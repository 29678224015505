import { API_BASE_URL } from '../config';
import store from '../store';
import { refreshAuthToken, updateAnonymousUserData } from './auth';

export const UPDATE_USER_DATA_REQUEST = 'UPDATE_USER_DATA_REQUEST';
const updateUserDataRequest = () => ({
    type: UPDATE_USER_DATA_REQUEST,
});

export const UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR';
const updateUserDataError = (error) => ({
    type: UPDATE_USER_DATA_ERROR,
    error,
});

export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
const updateUserDataSuccess = () => ({
    type: UPDATE_USER_DATA_SUCCESS,
});

export const FETCH_CONNECTED_USERS_DATA_REQUEST =
    'FETCH_CONNECTED_USERS_DATA_REQUEST';
const fetchConnectedUsersDataRequest = () => ({
    type: FETCH_CONNECTED_USERS_DATA_REQUEST,
});

export const FETCH_CONNECTED_USERS_DATA_ERROR =
    'FETCH_CONNECTED_USERS_DATA_ERROR';
const fetchConnectedUsersDataError = (error) => ({
    type: FETCH_CONNECTED_USERS_DATA_ERROR,
    error,
});

export const FETCH_CONNECTED_USERS_DATA_SUCCESS =
    'FETCH_CONNECTED_USERS_DATA_SUCCESS';
const fetchConnectedUsersDataSuccess = (data) => ({
    type: FETCH_CONNECTED_USERS_DATA_SUCCESS,
    data,
});

export const fetchConnectedUsersData = () => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(fetchConnectedUsersDataRequest());
    fetch(`${API_BASE_URL}/users/connected`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) => res.json())
        .then((data) => dispatch(fetchConnectedUsersDataSuccess(data)))
        .catch((error) => dispatch(fetchConnectedUsersDataError(error)));
};

export const postConnectedUsersData = (newUserData) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(fetchConnectedUsersDataRequest());
    fetch(`${API_BASE_URL}/users/connected/create`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ ...newUserData, email: newUserData.newEmail }),
    })
        .then(() => dispatch(fetchConnectedUsersData()))
        .catch((error) => dispatch(fetchConnectedUsersDataError(error)));
};

export const deleteConnectedUsersData = (userEmail) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(fetchConnectedUsersDataRequest());
    fetch(`${API_BASE_URL}/users/connected/delete`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ email: userEmail }),
    })
        .then(() => dispatch(fetchConnectedUsersData()))
        .catch((error) => dispatch(fetchConnectedUsersDataError(error)));
};

export const updateUserData = (data, preventRefresh) => (dispatch) => {
    const { authToken } = store.getState().auth;
    const { customerID } = data;

    dispatch(updateUserDataRequest());
    fetch(`${API_BASE_URL}/users/customer/${customerID}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then(() => dispatch(updateUserDataSuccess()))
        .then(() => (!preventRefresh ? dispatch(refreshAuthToken()) : false))
        .then(() =>
            !preventRefresh ? dispatch(fetchConnectedUsersData()) : false
        )
        .catch((error) => updateUserDataError(error));
};

export const updateCustomerFavorites = (sku, type) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(updateUserDataRequest());
    fetch(`${API_BASE_URL}/users/customer/favorites/edit`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ sku, type }),
    })
        .then(() => dispatch(refreshAuthToken()))
        .then(() => dispatch(updateUserDataSuccess()))
        .then(() => dispatch(fetchConnectedUsersData()))
        .catch((error) => updateUserDataError(error));
};

export const tokenize = (data) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(updateUserDataRequest());
    fetch(`${API_BASE_URL}/users/tokenize`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(() => dispatch(refreshAuthToken()))
        .then(() => dispatch(updateUserDataSuccess()))
        .catch((error) => updateUserDataError(error));
};

export const createPaymentMethod = (data) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(updateUserDataRequest());
    fetch(`${API_BASE_URL}/users/encrypt`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(() => dispatch(refreshAuthToken()))
        .then(() => dispatch(updateUserDataSuccess()))
        .catch((error) => updateUserDataError(error));
};

export const createAnonymousPaymentMethod = (data) => (dispatch) => {
    dispatch(updateUserDataRequest());
    fetch(`${API_BASE_URL}/users/encrypt/anonymous`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then(() =>
            dispatch(updateAnonymousUserData({ type: 'payment', ...data }))
        )
        .then(() => dispatch(updateUserDataSuccess()))
        .catch((error) => updateUserDataError(error));
};

export const deleteShippingAddress = (customerID, index) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(updateUserDataRequest());
    fetch(`${API_BASE_URL}/users/customer/${customerID}/delete`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ customerID, index }),
    })
        .then(() => dispatch(refreshAuthToken()))
        .then(() => dispatch(updateUserDataSuccess()))
        .then(() => dispatch(fetchConnectedUsersData()))
        .catch((error) => updateUserDataError(error));
};

export const resendConfirmation = (email) => {
    fetch(`${API_BASE_URL}/actions/resend/account`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });
};
