import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/auth';
import itemsReducer from './reducers/items';
import usersReducer from './reducers/users';
import cartsReducer from './reducers/carts';
import localCartReducer from './reducers/local-cart';
import ordersReducer from './reducers/orders';
import configuratorReducer from './reducers/configurator';
import invoicesReducer from './reducers/invoices';

import { loadAuthToken, loadLocalCart } from './local-storage';
import { setAuthToken, setAnonymousUser, refreshAuthToken } from './actions/auth';
import { setLocalCart } from './actions/local-cart';
import { loadAnonymousUser } from './session-storage';

const appReducer = combineReducers({
    localCart: localCartReducer,
    auth: authReducer,
    items: itemsReducer,
    users: usersReducer,
    carts: cartsReducer,
    orders: ordersReducer,
    configurator: configuratorReducer,
    invoices: invoicesReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
        state.auth = undefined;
        state.items.categories = Object.assign(
            [],
            state.items.categories.filter((category) => !category.id.startsWith('000'))
        );
        state.users = undefined;
        state.carts = undefined;
        state.localCart = undefined;
        state.invoices = undefined;
    }

    return appReducer(state, action);
};

const store = createStore(rootReducer, applyMiddleware(thunk));

const localCart = loadLocalCart();
if (localCart) {
    store.dispatch(setLocalCart(localCart));
}

const anonymousUser = loadAnonymousUser();
store.dispatch(setAnonymousUser(anonymousUser));

const authToken = loadAuthToken();
if (authToken) {
    store.dispatch(setAuthToken(authToken));
    store.dispatch(refreshAuthToken());
}

export default store;
