import {
	FETCH_INVOICE_DATA_REQUEST,
	FETCH_INVOICE_DATA_ERROR,
	FETCH_INVOICE_DATA_SUCCESS,
	FETCH_SINGLE_INVOICE_DATA_SUCCESS,
	POST_INVOICE_DATA_REQUEST,
	POST_INVOICE_DATA_ERROR,
	POST_INVOICE_DATA_SUCCESS,
	RESET_INVOICE_DATA
} from '../actions/invoices';

const initialState = {
	invoices: null,
	loading: false,
	error: null,
	submitted: false,
	successful: false
};

export default (state = initialState, action) => {
	if (action.type === FETCH_INVOICE_DATA_REQUEST) {
		return Object.assign({}, state, {
			loading: true
		});
	}
	if (action.type === FETCH_INVOICE_DATA_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			error: action.error
		});
	}
	if (action.type === FETCH_INVOICE_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			invoices: action.data
		});
	}
	if (action.type === FETCH_SINGLE_INVOICE_DATA_SUCCESS) {
		if (action.data.balance) {
			const { invoices } = state;

			return Object.assign({}, state, {
				loading: false,
				invoices:
					invoices && invoices.find((invoice) => invoice.invoiceID === action.data.invoice)
						? invoices
						: [ ...(invoices || []), action.data ]
			});
		}
	}
	if (action.type === POST_INVOICE_DATA_REQUEST) {
		return Object.assign({}, state, {
			loading: true,
			submitted: true,
			successful: false,
			error: null
		});
	}
	if (action.type === POST_INVOICE_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			submitted: true,
			successful: true
		});
	}
	if (action.type === POST_INVOICE_DATA_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			submitted: true,
			successful: false,
			error: action.error
		});
	}
	if (action.type === RESET_INVOICE_DATA) {
		return Object.assign({}, state, initialState);
	}

	return state;
};
