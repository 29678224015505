import {
    SET_LOCAL_CART,
    UPDATE_LOCAL_CART,
    REMOVE_FROM_LOCAL_CART,
    CLEAR_LOCAL_CART,
} from '../actions/local-cart';

import { clearLocalCart } from '../local-storage';

const initialState = {
    localCart: [],
};

export default (state = initialState, action) => {
    if (action.type === SET_LOCAL_CART) {
        return Object.assign({}, state, {
            localCart: action.localCart,
        });
    }

    if (action.type === UPDATE_LOCAL_CART) {
        const { item, quantity } = action;
        const { localCart } = state;

        const record = localCart.find(
            (cartItem) => cartItem.item.sku === item.sku
        );
        const otherItems = localCart.filter(
            (cartItem) => cartItem.item.sku !== item.sku
        );

        if (record) {
            if (item.path && item.path.includes('CONFIGURED')) {
                record.item = item;
            }

            record.quantity = quantity;
            return Object.assign({}, state, {
                localCart: [...otherItems, record],
            });
        }

        return Object.assign({}, state, {
            localCart: [...localCart, { item, quantity }],
        });
    }

    if (action.type === REMOVE_FROM_LOCAL_CART) {
        const { sku } = action;
        const { localCart } = state;

        return Object.assign({}, state, {
            localCart: localCart.filter(
                (cartItem) =>
                    cartItem.item.sku !== sku &&
                    cartItem.item.description !== sku
            ),
        });
    }

    if (action.type === CLEAR_LOCAL_CART) {
        clearLocalCart();
        return Object.assign({}, state, {
            localCart: [],
        });
    }

    return state;
};
