import _ from 'lodash';

export const formatTelephone = (number, ext) => {
    if (!number) {
        return '';
    }

    let formattedNumber = '';

    if (number.length === 11) {
        const countryCode = number[0];
        const first = number.slice(1, 4);
        const middle = number.slice(4, 7);
        const end = number.slice(7);

        formattedNumber = `+${countryCode} (${first}) ${middle}-${end}`;
    } else {
        const first = number.slice(0, 3);
        const middle = number.slice(3, 6);
        const end = number.slice(6);

        formattedNumber = `(${first}) ${middle}-${end}`;
    }

    if (ext) {
        return `${formattedNumber} [+${ext}]`;
    }

    return formattedNumber;
};

export const stripTelephone = (number) => number.replace(/\D/gi, '');

export const isAddressComplete = (address) => {
    if (!_.isEmpty(address)) {
        let isComplete = true;
        const keys = [
            'name',
            'address1',
            'city',
            'region',
            'countryCode',
            'postalCode',
        ];

        keys.forEach((property) => {
            if (!address[property]) {
                isComplete = false;
            }
        });

        return isComplete;
    }

    return false;
};

export const formatCurrency = (number) =>
    typeof number === 'number'
        ? `$${number
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : number;

export const padNumber = (num, length) => {
    let paddedNum = `${num}`;

    if (
        typeof num !== 'number' ||
        typeof length !== 'number' ||
        length > 100 ||
        length < 0
    ) {
        return paddedNum;
    }

    let remaining = length - paddedNum.length;

    while (remaining > 0) {
        paddedNum = `0${paddedNum}`;
        remaining--;
    }

    return paddedNum;
};

export const detectBrowser = () => {
    const { userAgent } = window.navigator;

    if (userAgent.includes('Firefox')) {
        return 'firefox';
    }
    if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
        return 'opera';
    }
    if (userAgent.includes('Trident')) {
        return 'ie';
    }
    if (userAgent.includes('Edge')) {
        return 'edge';
    }
    if (userAgent.includes('Chrome')) {
        return 'chrome';
    }
    if (userAgent.includes('Safari')) {
        return 'safari';
    }

    return 'unknown';
};

export const detectOS = () => {
    const { navigator } = window;
    const { userAgent, platform } = navigator;

    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    let os = '';

    if (macosPlatforms.includes(platform)) {
        os = 'Mac OS';
    } else if (iosPlatforms.includes(platform)) {
        os = 'iOS';
    } else if (windowsPlatforms.includes(platform)) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
};

export const generateValue = () => {
    const date = new Date();
    const random = `${Math.floor(Math.random() * 32).toString(32)}${Math.floor(
        Math.random() * 32
    ).toString(32)}`;
    const year = (date.getFullYear() - 2000).toString(32);
    const month = (date.getMonth() + 1).toString(32);
    const day = date.getDate().toString(32);
    const hour = date.getHours().toString(32);
    const leading = date.getMinutes() < 30 ? '0' : '';
    const minute = date.getMinutes().toString(32);
    const second = Math.floor(date.getSeconds() / 2).toString(32);

    const value =
        `-${random}${year}${month}${day}${hour}${leading}${minute}${second}`.toUpperCase();
    return value;
};

export const generateAnonymousID = () => {
    const date = (
        Math.round(new Date().getTime() / 1000) - 1546300801
    ).toString(32);
    const random = Math.floor(Math.random() * 32).toString(32);

    return `G${date}${random}`.toUpperCase();
};

export const formatCategoryName = (name) => {
    return name
        .toLowerCase()
        .replaceAll(' ', '-')
        .replaceAll('&', '')
        .replaceAll('/', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll(',', '')
        .replaceAll('--', '-')
        .replaceAll('---', '-');
};
