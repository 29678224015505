import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Autocomplete from './Autocomplete';

// import './styling/SearchBox.css';
// import './styling/MiniSearch.css';

const resources = [
    {
        name: 'Price Gun Repair Form',
        link: 'https://kenco-space.sfo2.cdn.digitaloceanspaces.com/downloads/Price-Gun-Repair-Form.pdf',
        external: true,
    },
];

class SearchBox extends React.Component {
    state = {
        searchString: '',
        selectedOption: -1,
        matches: null,
        query: '',
    };

    filterSearch = (name, terms) => {
        let isMatching = true;
        terms.forEach((term) => {
            const regex = new RegExp(term.replace(/\W/g, ''), 'gi');
            if (!regex.test(name)) {
                isMatching = false;
            }
        });

        return isMatching;
    };

    checkConfigured = (query) => {
        const regExp =
            /^(pri|2pri|ll|13|14|15|34|24|25|35|36|38|CX(?!1[02]00.)){1}[\d.]+/gi;
        return regExp.test(query);
    };

    handleKeyDown = (event) => {
        const { key } = event;
        const { searchString, selectedOption, matches } = this.state;

        if (!searchString) {
            this.setState({
                selectedOption: -1,
            });
        }

        if (matches) {
            if (key === 'ArrowDown') {
                event.preventDefault();
                if (
                    matches.categories.length + matches.brands.length >=
                    selectedOption + 1
                ) {
                    this.setState((prevState) => ({
                        selectedOption: prevState.selectedOption + 1,
                    }));
                }
            } else if (key === 'ArrowUp') {
                event.preventDefault();
                if (selectedOption > 0) {
                    this.setState((prevState) => ({
                        selectedOption: prevState.selectedOption - 1,
                    }));
                }
            }
        }
    };

    handleChange = (isMini) => {
        const { categories, brands } = this.props;

        this.setState(
            {
                searchString: isMini
                    ? this.miniSearch.value
                    : this.search.value,
            },
            () => {
                const { searchString } = this.state;

                const searchArray = searchString.split(' ');

                const categoryMatches = categories.filter((category) =>
                    this.filterSearch(category.name, searchArray)
                );
                const brandMatches = brands.filter((brand) =>
                    this.filterSearch(brand.name, searchArray)
                );
                const resourcesMatches = resources.filter((resource) =>
                    this.filterSearch(resource.name, searchArray)
                );

                this.setState({
                    matches: {
                        resources: resourcesMatches,
                        categories: categoryMatches,
                        brands: brandMatches,
                    },
                });
            }
        );
    };

    handleSubmit = (event, isSmall) => {
        event.preventDefault();

        const { history } = this.props;

        history.push(
            `/search?q=${isSmall ? this.miniSearch.value : this.search.value}`
        );

        if (isSmall) {
            this.miniSearch.value = '';
        } else {
            this.search.value = '';
        }
    };

    render() {
        const { selectedOption, searchString } = this.state;
        const { matches } = this.state;
        const { isMini } = this.props;

        if (isMini) {
            return (
                <div className="mini-search-container">
                    <div className="mini-search-box">
                        <form
                            className="mini-search-form"
                            id="mini-search-form"
                            onSubmit={(e) => this.handleSubmit(e, true)}
                        >
                            <div className="mini-search-box-icon">
                                <i className="fas fa-search mini-search-icon" />
                            </div>
                            <input
                                type="text"
                                id="mini-search"
                                className="mini-search"
                                placeholder="Search..."
                                ref={(miniSearch) => {
                                    this.miniSearch = miniSearch;
                                }}
                                onChange={() => this.handleChange(true)}
                            />
                        </form>
                    </div>
                </div>
            );
        }

        return (
            <form
                className="search-form"
                id="search-form"
                autoComplete="off"
                onKeyDown={(e) => this.handleKeyDown(e)}
                onSubmit={(e) => this.handleSubmit(e, false)}
            >
                <div className="search-box" id="search-box">
                    <i className="fas fa-search search-icon" />
                    <input
                        type="text"
                        className="search-input"
                        id="search-input"
                        placeholder={
                            window.innerWidth > 1000
                                ? 'Search labels, tags, printers, and more!'
                                : 'Search our products...'
                        }
                        ref={(search) => {
                            this.search = search;
                        }}
                        onChange={() => this.handleChange(false)}
                    />
                    <button
                        type="submit"
                        className={`search-submit ${
                            searchString ? 'active' : 'disabled'
                        }`}
                        id="search-submit"
                        disabled={!searchString}
                    >
                        <span>Go!</span>
                    </button>
                </div>
                {matches ? (
                    <Autocomplete
                        matches={matches}
                        selectedOption={selectedOption}
                    />
                ) : (
                    ''
                )}
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const { categories, brands } = state.items;
    const { currentUser } = state.auth;

    return {
        categories,
        brands,
        currentUser,
    };
};

export default withRouter(connect(mapStateToProps)(SearchBox));
