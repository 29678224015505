import React from 'react';
import { Link } from 'react-router-dom';

import { DOWNLOADS_URL } from '../config';

export default function FooterLinkList(props) {
    const { links, header, openLiveChatEnglish } = props;

    const renderLink = (link) => {
        const { whereTo, external, text, type } = link;

        if (type === 'download' && text === 'Price Gun Repair Form') {
            return (
                <a
                    href={`${DOWNLOADS_URL}Price-Gun-Repair-Form.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textTransform: 'uppercase' }}
                >
                    {text}
                </a>
            );
        }

        if (type === 'chat') {
            return (
                <span
                    role="button"
                    style={{ cursor: 'pointer', textTransform: 'uppercase' }}
                    onClick={openLiveChatEnglish}
                >
                    {text}
                </span>
            );
        }

        if (external) {
            return (
                <a
                    href={link.whereTo}
                    target={link.external ? '_blank' : ''}
                    rel="noopener noreferrer"
                    style={{ textTransform: 'uppercase' }}
                >
                    {link.text}
                </a>
            );
        }

        return (
            <Link to={`${whereTo}`} style={{ textTransform: 'uppercase' }}>
                {text}
            </Link>
        );
    };

    const renderLinks = () =>
        links.map((link, index) => (
            <li
                key={`footer-section-links-list-${index}`}
                className="footer-section-links-list-item"
            >
                {renderLink(link)}
            </li>
        ));

    return (
        <div
            className={`footer-section footer-section-top resources-section footer-section-${header}`}
        >
            <h5 className="footer-section-header">{header}</h5>
            <ul
                className={`footer-section-links-list footer-section-links-${header}`}
            >
                {renderLinks()}
            </ul>
        </div>
    );
}
