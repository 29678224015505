import React from 'react';

export default function ErrorPage() {
	return (
		<div className="page-container results-page-container error-page-container static-container">
			<div className="error-page static-page">
				<h1 className="page-header error-header">Oops! It looks like there was an error.</h1>
				<p>We&rsquo;re working on getting that fixed. You will be redirected shortly.</p>
			</div>
		</div>
	);
}
