import {
	SET_AUTH_TOKEN,
	CLEAR_AUTH,
	AUTH_REQUEST,
	AUTH_ERROR,
	AUTH_SUCCESS,
	UPDATE_ANONYMOUS_USER_DATA,
	SET_ANONYMOUS_USER,
} from '../actions/auth';

import { clearAuthToken } from '../local-storage';
import { saveAnonymousUser, clearAnonymousUser } from '../session-storage';

import { generateAnonymousID } from '../scripts/functions';

const anonymousUser = {
	anonymous: true,
	active: true,
	billingAddress: {
		address1: '',
		address2: '',
		address3: '',
		approver: '',
		city: '',
		countryCode: '',
		id: 0,
		locationID: '',
		name: '',
		postalCode: '',
		region: '',
		telephoneNo: '',
	},
	customerBalance: 0,
	customerID: generateAnonymousID(),
	customerName: '',
	email: '',
	faxNo: '',
	fedexNo: '',
	firstName: 'Guest',
	lastName: 'User',
	locationID: '',
	manualItems: false,
	paymentMethods: [],
	permissions: {
		addShipping: true,
		addUsers: true,
		modifyBilling: true,
		modifyShipping: true,
		requireApproval: false,
	},
	shippingAddresses: [
		{
			address1: '',
			address2: '',
			address3: '',
			approver: '',
			city: '',
			countryCode: '',
			id: 0,
			locationID: '',
			name: '',
			postalCode: '',
			region: '',
			telephoneNo: '',
		},
	],
	skipSurvey: false,
	subusers: false,
	taxExempt: false,
	telephoneExt: '',
	telephoneNo: '',
	terms: false,
	upsNo: '',
};

const initialState = {
	authToken: null,
	currentUser: anonymousUser,
	loading: false,
	error: null,
};

export default (state = initialState, action) => {
	if (action.type === SET_AUTH_TOKEN) {
		return Object.assign({}, state, {
			authToken: action.authToken,
		});
	} if (action.type === CLEAR_AUTH) {
		clearAuthToken();
		return Object.assign({}, state, {
			authToken: null,
			currentUser: anonymousUser,
		});
	} if (action.type === AUTH_REQUEST) {
		return Object.assign({}, state, {
			loading: true,
			error: null,
		});
	} if (action.type === AUTH_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			error: action.error,
		});
	} if (action.type === AUTH_SUCCESS) {
		clearAnonymousUser();
		return Object.assign({}, state, {
			loading: false,
			error: null,
			currentUser: action.currentUser,
		});
	} if (action.type === SET_ANONYMOUS_USER) {
		if (action.currentUser) {
			return Object.assign({}, state, {
				currentUser: action.currentUser,
			});
		}

		saveAnonymousUser(anonymousUser);
	} if (action.type === UPDATE_ANONYMOUS_USER_DATA) {
		const { data } = action;
		const { type, action: verb } = data;

		delete data.type;

		const update = {};

		if (verb === 'delete') {
			if (type === 'payment') {
				const { lastFour } = data;

				update.paymentMethods = state.currentUser.paymentMethods.filter(method => method.lastFour !== lastFour);
			}
		} else if (type === 'billing') {
			delete data.customerID;
			update.billingAddress = data;
		} else if (type === 'shipping') {
			delete data.customerID;
			update.shippingAddresses = [data];
			update.email = data.email;
		} else if (type === 'payment') {
			data.lastFour = data.number.slice(-4);
			data.expiration = { month: data.month, year: `20${data.year}` };

			delete data.number;
			delete data.customerID;
			delete data.month;
			delete data.year;

			update.paymentMethods = [...state.currentUser.paymentMethods, data];
		}

		saveAnonymousUser({ ...state.currentUser, ...update });
		return Object.assign({}, state, {
			loading: false,
			error: null,
			currentUser: { ...state.currentUser, ...update },
		});
	}

	return state;
};
