import jwtDecode from 'jwt-decode';

import { API_BASE_URL } from '../config';
import { normalizeResponseErrors } from './utils';
import store from '../store';
import { saveAuthToken, clearAuthToken } from '../local-storage';
import { fetchCartData } from './carts';
import { fetchConnectedUsersData } from './users';
import { fetchNavigationData } from './items';

import { recordSignup } from '../scripts/tracking';

const getOrderVariables = async () => {
    const { authToken } = store.getState().auth;

    const orders = await fetch(`${API_BASE_URL}/orders/all`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    }).then((res) => res.json());

    const sortedOrders = orders.sort((a, b) =>
        b.orderDate.localeCompare(a.orderDate)
    );

    const order = sortedOrders[0];
    const variables = {};

    if (order) {
        const { orderID } = order;

        variables.Last_Order_ID = orderID;
        variables.Number_of_Orders = orders.length;
    } else {
        variables.Last_Order_ID = "Hasn't ordered yet";
        variables.Number_of_Orders = 0;
    }

    return variables;
};

export const LOG_OUT = 'LOG_OUT';
export const logOut = () => ({
    type: LOG_OUT,
});

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const setAuthToken = (authToken) => ({
    type: SET_AUTH_TOKEN,
    authToken,
});

export const CLEAR_AUTH = 'CLEAR_AUTH';
export const clearAuth = () => ({
    type: CLEAR_AUTH,
});

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const authRequest = () => ({
    type: AUTH_REQUEST,
});

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const authSuccess = (currentUser) => ({
    type: AUTH_SUCCESS,
    currentUser,
});

export const AUTH_ERROR = 'AUTH_ERROR';
export const authError = (error) => ({
    type: AUTH_ERROR,
    error,
});

export const SET_ANONYMOUS_USER = 'SET_ANONYMOUS_USER';
export const setAnonymousUser = (currentUser) => ({
    type: SET_ANONYMOUS_USER,
    currentUser,
});

export const UPDATE_ANONYMOUS_USER_DATA = 'UPDATE_ANONYMOUS_USER_DATA';
export const updateAnonymousUserData = (data) => ({
    type: UPDATE_ANONYMOUS_USER_DATA,
    data,
});

const storeAuthInfo = async (authToken, userDetails, dispatch) => {
    const decodedToken = jwtDecode(authToken);
    const currentUser = { ...decodedToken.user, ...userDetails };
    dispatch(setAuthToken(authToken));
    dispatch(authSuccess(currentUser));
    saveAuthToken(authToken);

    const { email, firstName, lastName, customerID, billingAddress } =
        currentUser;
    const { telephoneNo } = billingAddress;

    // if (window.smartsupp) {
    //     window.smartsupp('name', `${firstName} ${lastName}`);
    //     window.smartsupp('email', email);
    //     window.smartsupp('phone', telephoneNo);

    //     const variables = await getOrderVariables();
    //     window.smartsupp('variables', {
    //         Email: email,
    //         Customer_ID: customerID,
    //         ...(variables || {}),
    //     });
    // }

    if (window.smartlook) {
        console.log(window.smartlook);
        try {
            const variables = await getOrderVariables();
            window.smartlook('identify', customerID, {
                email,
                firstName,
                lastName,
                phone: telephoneNo,
                ...(variables || {}),
            });
        } catch (e) {
            console.log(e);
            // could not identify
        }
    }
};

export const login = (email, password) => (dispatch) => {
    dispatch(authRequest());
    return fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: email.toLowerCase(),
            password,
        }),
    })
        .then((res) => normalizeResponseErrors(res))
        .then((res) => res.json())
        .then(({ authToken: newAuthToken, userDetails }) =>
            storeAuthInfo(newAuthToken, userDetails, dispatch)
        )
        .then(() => dispatch(fetchNavigationData()))
        .then(() => dispatch(fetchCartData()))
        .then(async () => {
            const { currentUser } = store.getState().auth;
            const { permissions } = currentUser;
            const { addUsers } = permissions;

            if (addUsers) {
                return dispatch(fetchConnectedUsersData());
            }

            return false;
        })
        .catch((err) => dispatch(authError(err)));
};

export const createNewUser =
    (email, password, firstName, lastName) => (dispatch) => {
        dispatch(authRequest());

        recordSignup(email.toLowerCase(), firstName, lastName);
        return fetch(`${API_BASE_URL}/users/user/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email.toLowerCase(),
                password,
                firstName,
                lastName,
            }),
        }).catch((err) => dispatch(authError(err)));
    };

export const refreshAuthToken = () => (dispatch, getState) => {
    dispatch(authRequest());
    const { authToken } = getState().auth;
    return (
        fetch(`${API_BASE_URL}/auth/refresh`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        })
            // .then(res => normalizeResponseErrors(res))
            .then((res) => res.json())
            .then(({ authToken: newAuthToken, userDetails }) =>
                storeAuthInfo(newAuthToken, userDetails, dispatch)
            )
            .catch((err) => {
                dispatch(authError(err));
                dispatch(clearAuth());
                clearAuthToken(authToken);
            })
    );
};
