import _ from 'lodash';

import { API_BASE_URL } from '../config';
import store from '../store';

export const FETCH_SEARCH_DATA_REQUEST = 'FETCH_SEARCH_DATA_REQUEST';
const fetchSearchDataRequest = () => ({
    type: FETCH_SEARCH_DATA_REQUEST,
});

export const FETCH_SEARCH_DATA_ERROR = 'FETCH_SEARCH_DATA_ERROR';
const fetchSearchDataError = (error) => ({
    type: FETCH_SEARCH_DATA_ERROR,
    error,
});

export const FETCH_SEARCH_DATA_SUCCESS = 'FETCH_SEARCH_DATA_SUCCESS';
const fetchSearchDataSuccess = (data, noResults) => ({
    type: FETCH_SEARCH_DATA_SUCCESS,
    data,
    noResults,
});

export const FETCH_SEARCH_DATA_CLEAR = 'FETCH_SEARCH_DATA_CLEAR';
export const fetchSearchDataClear = () => ({
    type: FETCH_SEARCH_DATA_CLEAR,
});

export const fetchSearchData = (query) => async (dispatch) => {
    const { currentUser } = store.getState().auth;
    const customerID =
        currentUser && !currentUser.anonymous ? currentUser.customerID : '';
    const userID =
        currentUser && !currentUser.anonymous ? currentUser.email : '';

    const { ip } = await fetch('https://api.ipify.org/?format=json').then(
        (res) => res.json()
    );

    dispatch(fetchSearchDataRequest());
    fetch(
        `${API_BASE_URL}/search?keyword=${query}&ipAddress=${ip || 'unknown'}${
            customerID ? `&customerID=${customerID}&userID=${userID}` : ''
        }`
    )
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((items) =>
            dispatch(fetchSearchDataSuccess(items, _.isEmpty(items)))
        )
        .catch((error) => dispatch(fetchSearchDataError(error)));
};
