export const loadAnonymousUser = () => JSON.parse(sessionStorage.getItem('currentUser'));

export const saveAnonymousUser = (currentUser) => {
	try {
		sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
	} catch (e) {
		// Could not set anonymous user.
	}
};

export const clearAnonymousUser = () => {
	try {
		sessionStorage.removeItem('currentUser');
	} catch (e) {
		// Could not remove anonymous user.
	}
};

export const loadSeen = () => JSON.parse(sessionStorage.getItem('seen'));

export const saveSeen = () => {
	try {
		sessionStorage.setItem('seen', JSON.stringify(true));
	} catch (e) {
		// Could not save seen.
	}
};

export const loadAffiliates = () => {
	const affiliates = JSON.parse(sessionStorage.getItem('affiliates'));

	return affiliates;
};

export const saveAffiliates = (affiliates) => {
	try {
		sessionStorage.setItem('affiliates', JSON.stringify(affiliates));
	} catch (e) {
		// Could not save affiliates.
	}
};

export const loadReferral = () => {
	const referral = JSON.parse(sessionStorage.getItem('referral'));

	return referral || null;
};

export const saveReferral = (source, type) => {
	try {
		sessionStorage.setItem('referral', JSON.stringify({ source, type }));
	} catch (e) {
		// Could not save referral.
	}
};

export const loadCoupon = () => {
	const coupon = sessionStorage.getItem('coupon');

	return coupon || null;
};

export const saveCoupon = (coupon) => {
	try {
		sessionStorage.setItem('coupon', coupon);
	} catch (e) {
		// Could not save coupon.
	}
};
