import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

export default class Loading extends React.Component {
    state = { show: null };

    componentDidMount = () => {
        this.counter = setTimeout(
            () =>
                this.setState({
                    show: (
                        <div className="configurator-loading-container">
                            <CircularProgress
                                color="primary"
                                style={{ marginRight: '0.5em' }}
                            />
                            <span>Loading...</span>
                        </div>
                    ),
                }),
            1000
        );
    };

    componentWillUnmount = () => {
        clearTimeout(this.counter);
    };

    render() {
        const { show } = this.state;

        return (
            <div className="page-container configurator-page-container">
                {show}
            </div>
        );
    }
}
