import {
	FETCH_ORDER_DATA_REQUEST,
	FETCH_ORDER_DATA_ERROR,
	FETCH_ORDER_DATA_SUCCESS,
	POST_ORDER_DATA_REQUEST,
	POST_ORDER_DATA_SUCCESS,
	POST_ORDER_DATA_ERROR
} from '../actions/orders';

const initialState = {
	orders: null,
	invoices: null,
	loading: false,
	error: null,
	submitted: false,
	successful: false
};

export default (state = initialState, action) => {
	if (action.type === FETCH_ORDER_DATA_REQUEST) {
		return Object.assign({}, state, {
			loading: true
		});
	}
	if (action.type === FETCH_ORDER_DATA_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			error: action.error
		});
	}
	if (action.type === FETCH_ORDER_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			orders: action.data
		});
	}
	if (action.type === POST_ORDER_DATA_REQUEST) {
		return Object.assign({}, state, {
			loading: true,
			submitted: true,
			successful: false,
			error: null
		});
	}
	if (action.type === POST_ORDER_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			submitted: true,
			successful: true
		});
	}
	if (action.type === POST_ORDER_DATA_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			submitted: true,
			successful: false,
			error: action.error
		});
	}

	return state;
};
