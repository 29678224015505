import React from 'react';
import moment from 'moment-timezone';

import ErrorPage from './ErrorPage';

import { API_BASE_URL } from '../config';
import { detectBrowser, detectOS } from '../scripts/functions';

export default class ErrorBoundary extends React.Component {
	state = { error: false }

	componentDidUpdate = () => {
		const { history, pathname } = this.props;
		const { error } = this.state;

		if (error) {
			setTimeout(() => {
				this.setState({ error: false }, () => {
					if (pathname !== '/error' && pathname !== '/account') {
						history.push(pathname);
					} else {
						history.push('/');
					}
				});
			}, 5000);
		}
	}

	componentDidCatch = (error, info) => {
		const { loggedIn, currentUser } = this.props;
		this.setState({ error: true });

		const errorData = {
			loggedIn: !!loggedIn,
			customerID: currentUser ? currentUser.customerID : '',
			email: currentUser ? currentUser.email : '',
			os: detectOS(),
			browser: detectBrowser(),
			time: moment().tz('America/Chicago').format('YYYY-MM-DD H:mm'),
			error: `${error.stack}`,
		};

		console.log(errorData);
		// this.logError(errorData);
	}

	logError = (errorData) => {
		fetch(`${API_BASE_URL}/error`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({ errorData }),
		});
	}

	render() {
		const { children } = this.props;
		const { error } = this.state;

		if (error) {
			return <ErrorPage />;
		}

		return children;
	}
}
