import {
	UPDATE_USER_DATA_REQUEST,
	UPDATE_USER_DATA_ERROR,
	UPDATE_USER_DATA_SUCCESS,
	FETCH_CONNECTED_USERS_DATA_REQUEST,
	FETCH_CONNECTED_USERS_DATA_ERROR,
	FETCH_CONNECTED_USERS_DATA_SUCCESS,
} from '../actions/users';

const initialState = {
	loading: false,
	error: false,
	connectedUsers: [],
};

export default (state = initialState, action) => {
	if (action.type === UPDATE_USER_DATA_REQUEST
		|| action.type === FETCH_CONNECTED_USERS_DATA_REQUEST) {
		return Object.assign({}, state, {
			loading: true,
		});
	} if (action.type === UPDATE_USER_DATA_ERROR
		|| action.type === FETCH_CONNECTED_USERS_DATA_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			error: action.error,
		});
	} if (action.type === UPDATE_USER_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			error: null,
		});
	} if (action.type === FETCH_CONNECTED_USERS_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			error: null,
			connectedUsers: action.data,
		});
	}

	return state;
};
