import { API_BASE_URL } from '../config';
import store from '../store';

export const FETCH_NAVIGATION_DATA_REQUEST = 'FETCH_NAVIGATION_DATA_REQUEST';
const fetchNavigationDataRequest = () => ({
    type: FETCH_NAVIGATION_DATA_REQUEST,
});

export const FETCH_NAVIGATION_DATA_ERROR = 'FETCH_NAVIGATION_DATA_ERROR';
const fetchNavigationDataError = (error) => ({
    type: FETCH_NAVIGATION_DATA_ERROR,
    error,
});

export const FETCH_NAVIGATION_DATA_SUCCESS = 'FETCH_NAVIGATION_DATA_SUCCESS';
const fetchNavigationDataSuccess = (data) => ({
    type: FETCH_NAVIGATION_DATA_SUCCESS,
    data,
});

export const fetchNavigationData = () => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(fetchNavigationDataRequest());
    fetch(
        `${API_BASE_URL}/navigation/${authToken ? 'loggedin' : 'anonymous'}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }
    )
        .then(async (res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((navigation) => dispatch(fetchNavigationDataSuccess(navigation)))
        .catch((error) => dispatch(fetchNavigationDataError(error)));
};

export const FETCH_ITEMS_FROM_CATEGORIES_DATA_REQUEST =
    'FETCH_ITEMS_FROM_CATEGORIES_DATA_REQUEST';
const fetchItemsFromCategoriesDataRequest = () => ({
    type: FETCH_ITEMS_FROM_CATEGORIES_DATA_REQUEST,
});

export const FETCH_ITEMS_FROM_CATEGORIES_DATA_ERROR =
    'FETCH_ITEMS_FROM_CATEGORIES_DATA_ERROR';
const fetchItemsFromCategoriesDataError = (error) => ({
    type: FETCH_ITEMS_FROM_CATEGORIES_DATA_ERROR,
    error,
});

export const FETCH_ITEMS_FROM_CATEGORIES_DATA_SUCCESS =
    'FETCH_ITEMS_FROM_CATEGORIES_DATA_SUCCESS';
const fetchItemsFromCategoriesDataSuccess = (data, noResults) => ({
    type: FETCH_ITEMS_FROM_CATEGORIES_DATA_SUCCESS,
    data,
    noResults,
});

export const FETCH_ITEMS_FROM_CATEGORIES_DATA_CLEAR =
    'FETCH_ITEMS_FROM_CATEGORIES_DATA_CLEAR';
export const fetchItemsFromCategoriesDataClear = () => ({
    type: FETCH_ITEMS_FROM_CATEGORIES_DATA_CLEAR,
});

export const fetchItemsFromCategoriesData = (itemsId) => (dispatch) => {
    dispatch(fetchItemsFromCategoriesDataRequest());
    fetch(`${API_BASE_URL}/items/path?path=${itemsId}`)
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((items) =>
            dispatch(fetchItemsFromCategoriesDataSuccess(items, !items.length))
        )
        .catch((error) => dispatch(fetchItemsFromCategoriesDataError(error)));
};

export const fetchItemsFromBrandsPrefixes = (name, prefixes) => (dispatch) => {
    dispatch(fetchItemsFromCategoriesDataRequest());
    fetch(
        `${API_BASE_URL}/items/prefixes?name=${name}&${prefixes
            .map((prefix) => `prefixes[]=${prefix}`)
            .join('&')}`
    )
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((items) => dispatch(fetchItemsFromCategoriesDataSuccess(items)))
        .catch((error) => dispatch(fetchItemsFromCategoriesDataError(error)));
};

export const fetchClearanceItems = () => (dispatch) => {
    dispatch(fetchItemsFromCategoriesDataRequest());
    fetch(`${API_BASE_URL}/items/clearance`)
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((items) => dispatch(fetchItemsFromCategoriesDataSuccess(items)))
        .catch((error) => dispatch(fetchItemsFromCategoriesDataError(error)));
};

export const fetchItemsBySku = (sku, customerID) => (dispatch) => {
    dispatch(fetchItemsFromCategoriesDataRequest());
    fetch(`${API_BASE_URL}/items/single?sku=${sku}&customerID=${customerID}`)
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((item) => {
            if (item)
                return dispatch(fetchItemsFromCategoriesDataSuccess([item]));
        })
        .catch((error) => dispatch(fetchItemsFromCategoriesDataError(error)));
};

export const fetchItemsByMultipleSkus = (skus) => (dispatch) => {
    dispatch(fetchItemsFromCategoriesDataRequest());
    fetch(
        `${API_BASE_URL}/items/multiple?${skus
            .map((sku) => `skus=${sku}`)
            .join('&&')}`
    )
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((items) => {
            if (items)
                return dispatch(fetchItemsFromCategoriesDataSuccess(items));
        })
        .catch((error) => dispatch(fetchItemsFromCategoriesDataError(error)));
};

export const FETCH_GROUP_DATA_REQUEST = 'FETCH_GROUP_DATA_REQUEST';
const fetchGroupDataRequest = () => ({
    type: FETCH_GROUP_DATA_REQUEST,
});

export const FETCH_GROUP_DATA_ERROR = 'FETCH_GROUP_DATA_ERROR';
const fetchGroupDataError = (error) => ({
    type: FETCH_GROUP_DATA_ERROR,
    error,
});

export const FETCH_GROUP_DATA_SUCCESS = 'FETCH_GROUP_DATA_SUCCESS';
const fetchGroupDataSuccess = (data) => ({
    type: FETCH_GROUP_DATA_SUCCESS,
    data,
});

export const fetchGroupData = (groupString) => (dispatch) => {
    dispatch(fetchGroupDataRequest());
    fetch(`${API_BASE_URL}/items/related?groupString=${groupString}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then(async (res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((data) =>
            dispatch(fetchGroupDataSuccess({ groupString, ...data }))
        )
        .catch((error) => dispatch(fetchGroupDataError(error)));
};

export const FETCH_VARIATION_DATA_REQUEST = 'FETCH_VARIATION_DATA_REQUEST';
const fetchVariationDataRequest = () => ({
    type: FETCH_VARIATION_DATA_REQUEST,
});

export const FETCH_VARIATION_DATA_ERROR = 'FETCH_VARIATION_DATA_ERROR';
const fetchVariationDataError = (error) => ({
    type: FETCH_VARIATION_DATA_ERROR,
    error,
});

export const FETCH_VARIATION_DATA_SUCCESS = 'FETCH_VARIATION_DATA_SUCCESS';
const fetchVariationDataSuccess = (data) => ({
    type: FETCH_VARIATION_DATA_SUCCESS,
    data,
});

export const fetchVariationData = (variationId) => (dispatch) => {
    dispatch(fetchVariationDataRequest());
    fetch(`${API_BASE_URL}/items/variations?variationId=${variationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then(async (res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((data) =>
            dispatch(fetchVariationDataSuccess({ variationId, ...data }))
        )
        .catch((error) => dispatch(fetchVariationDataError(error)));
};
