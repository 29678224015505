const config = {
    API_BASE_URL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : '/api',
    CATEGORY_IMG_URL: 'https://kenco-space.sfo2.cdn.digitaloceanspaces.com/images/',
    ITEM_IMG_URL: 'https://kenco-space.sfo2.cdn.digitaloceanspaces.com/images/',
    DOWNLOADS_URL: 'https://kenco-space.sfo2.cdn.digitaloceanspaces.com/downloads/',
    VIDEOS_URL: 'https://kenco-space.sfo2.cdn.digitaloceanspaces.com/videos/',
    ENGLISH_DEPT: 31936,
    ENGLISH_WIN: 30610,
    SPANISH_DEPT: 31949,
    SPANISH_WIN: 30624,
    TEL_NO: '18005373336',
    FAX_NO: '14143526533',
    SHOPPER_APPROVED_ID: '16749',
    SHOPPER_APPROVED_TOKEN: '2qG73nDC5bRSsFQ',
    RECAPTCHA_SITE_KEY: '6LdPGa8UAAAAAIjVDtQ2Z5Fl16XjbXx1IVxDQVbp',
    HIDE_CATEGORIES: ['998'],
    NO_SHIPPING_OPTIONS: ['C221663', 'C245760'],
    AMAZON_BASE_URL: 'https://amzn.to/',
    PAYPAL_CLIENT_ID:
        process.env.NODE_ENV === 'development'
            ? 'sb'
            : 'Af0CrQfd1SL3D5WvewXZuXu865Ds3rRWLjbgWqipXyeXhq58cBti1oxZ_HvkT3H2b-qxz4XAHCWzfgub',
};

module.exports = config;
