/* eslint import/no-webpack-loader-syntax: off */

import React from 'react';

import amex from '../storage/amex.svg';
import discover from '../storage/discover.svg';
import master from '../storage/master.svg';
import visa from '../storage/visa.svg';

import paypal from '../storage/paypal.svg';
import fb from '../storage/fb.svg';
// import instagram from '../storage/instagram.svg';
import linkedin from '../storage/linkedin.svg';
import googlemaps from '../storage/google-maps.svg';
import amazon from '../storage/amazon.svg';
import youtube from '../storage/youtube.svg';

import FooterLinkList from './FooterLinkList';

export default function Footer(props) {
    const { showBorder, openLiveChatEnglish } = props;

    const linkSets = [
        {
            header: 'Build-A-Label™',
            links: [
                {
                    whereTo: '/build/rolls',
                    text: 'Labels on Rolls',
                    external: false,
                },
                {
                    whereTo: '/build/sheets',
                    text: 'Laser Sheets',
                    external: false,
                },
                {
                    whereTo: '/build/custom',
                    text: 'Custom Printed Labels',
                    external: false,
                },
                {
                    whereTo: '/build/price-gun',
                    text: 'Custom Price Gun Labels',
                    external: false,
                },
            ],
        },
        {
            header: 'Service & Support',
            links: [
                {
                    whereTo: '/pay',
                    text: 'Pay Invoices',
                    external: false,
                },
                {
                    whereTo: '/barcodes',
                    text: 'Barcode Generator',
                    external: false,
                },
                {
                    whereTo: 'https://kencolabel.fastsupport.com/',
                    text: 'Remote Support',
                    external: true,
                },
                {
                    whereTo: 'https://kencostore.hostedftp.com/~kencostore/',
                    text: 'Upload Art',
                    external: true,
                },
                {
                    whereTo: '/',
                    type: 'download',
                    text: 'Price Gun Repair Form',
                    external: false,
                },
            ],
        },
        {
            header: 'Information',
            links: [
                {
                    whereTo: '/contact',
                    text: 'Contact Us',
                    external: false,
                },
                {
                    whereTo: '/faq',
                    text: 'FAQ',
                    external: false,
                },
                {
                    whereTo: '/returns',
                    text: 'Return Policy',
                    external: false,
                },
                {
                    whereTo: '/price-match',
                    text: 'Price Match Guarantee',
                    external: false,
                },
                {
                    whereTo: '/privacy',
                    text: 'Privacy Notice',
                    external: false,
                },
                {
                    whereTo:
                        'https://kenco-space.sfo2.digitaloceanspaces.com/downloads/Kenco%20New%20Customer%20Application.pdf',
                    text: 'New Customer Form',
                    type: 'download',
                    external: true,
                },
                {
                    whereTo: '/fraud-prevention',
                    text: 'Fraud Prevention',
                    external: false,
                },
                {
                    whereTo: '/terms/use',
                    text: 'Terms of Use',
                    external: false,
                },
                {
                    whereTo: '/terms/commercial',
                    text: 'Commercial Terms',
                    external: false,
                },
                {
                    whereTo: '/patents-trademarks',
                    text: 'Patents & Trademarks',
                    external: false,
                },
            ],
        },
    ];

    const renderLinks = () =>
        linkSets.map((linkSet, index) => (
            <FooterLinkList
                key={`link-set-${index}`}
                links={linkSet.links}
                header={linkSet.header}
                openLiveChatEnglish={openLiveChatEnglish}
            />
        ));

    return (
        <footer
            className="footer"
            style={{ borderTop: showBorder ? '1px solid #002677' : '' }}
        >
            <div className="footer-containers">
                <div className="footer-container footer-container-lower hide-mobile">
                    <div className="footer-section payment-section">
                        <div className="credit-cards">
                            <img
                                src={visa}
                                className="payment-icon"
                                alt="Visa"
                            />
                            <img
                                src={master}
                                className="payment-icon"
                                alt="MasterCard"
                            />
                            <img
                                src={discover}
                                className="payment-icon"
                                alt="Discover Card"
                            />
                            <img
                                src={amex}
                                className="payment-icon"
                                alt="American Express"
                            />
                        </div>
                        <div
                            className="credit-cards"
                            style={{
                                marginTop: '1rem',
                                backgroundColor: 'white',
                                width: 150,
                                padding: '5px 0 5px 20px',
                                borderRadius: 5,
                                textAlign: 'center',
                                height: 'fit-content',
                            }}
                        >
                            <img
                                src={paypal}
                                alt="PayPal"
                                style={{ width: 120 }}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-container footer-container-upper">
                    {renderLinks()}
                </div>
            </div>
            <div className="social-media-footer standard-width">
                <a
                    className="social-media-link"
                    href="https://www.linkedin.com/company/1182638/admin/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={linkedin} alt="Linked In"></img>
                </a>
                {/* <a className="social-media-link">
                    <img src={instagram} alt="Instagram"></img>
                </a> */}
                <a
                    className="social-media-link"
                    href="https://www.amazon.com/stores/page/83EC9D37-C4C1-44C6-8850-8F3C79A2AB01"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={amazon} alt="Amazon"></img>
                </a>
                <a
                    className="social-media-link"
                    href="https://www.google.com/maps/place/Kenco+Label+%26+Tag+Co.,+LLC./@43.1365362,-87.9508301,17z/data=!3m1!4b1!4m5!3m4!1s0x88051dea21e86ecf:0x6a33bc50b057d819!8m2!3d43.1365362!4d-87.9486414"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={googlemaps} alt="Google Maps"></img>
                </a>
                <a
                    className="social-media-link"
                    href="https://www.youtube.com/c/kencolabel"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={youtube} alt="YouTube"></img>
                </a>
                <a
                    className="social-media-link"
                    href="https://www.facebook.com/kencolabel/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={fb} alt="Facebook"></img>
                </a>
            </div>
            <div className="disclaimer">
                <p className="disclaimer-paragraph small-text">
                    Prices, specifications, and images are subject to change
                    without notice. Not responsible for typographical or
                    illustrative errors.
                </p>
                <p className="disclaimer-paragraph small-text">
                    Manufacturer rebates, terms, conditions, and expiration
                    dates are subject to manufacturer&#39;s printed forms.
                </p>
                <p className="copyright small-text">
                    ©2022 Kenco Label &amp; Tag Co. LLC.
                </p>
            </div>
            <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
                <h3>Attributions</h3>
                <a
                    href="https://iconscout.com/icons/youtube"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Youtube Icon
                </a>{' '}
                by{' '}
                <a href="https://iconscout.com/contributors/icon-mafia">
                    Icon Mafia
                </a>{' '}
                on <a href="https://iconscout.com">IconScout</a>
                <a
                    href="https://iconscout.com/icons/instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Instagram Icon
                </a>{' '}
                by{' '}
                <a href="https://iconscout.com/contributors/jagathish">
                    Jagathish Saravanan
                </a>{' '}
                on <a href="https://iconscout.com">IconScout</a>
                <a
                    href="https://iconscout.com/icons/amazon"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Amazon Icon
                </a>{' '}
                by{' '}
                <a href="https://iconscout.com/contributors/pixel-icons">
                    Pixel Icons
                </a>{' '}
                on <a href="https://iconscout.com">IconScout</a>
                <a
                    href="https://iconscout.com/icons/google-maps"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Google Maps Icon
                </a>{' '}
                by{' '}
                <a href="https://iconscout.com/contributors/oviyan">
                    Amoghdesign
                </a>{' '}
                on <a href="https://iconscout.com">IconScout</a>
            </div>
        </footer>
    );
}
