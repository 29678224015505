import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';

import logo from '../storage/kenco-logo.svg';
import { TEL_NO } from '../config';
import createDropdownData from '../dropdown-data';
import { formatTelephone } from '../scripts/functions';

import { clearAuth, logOut } from '../actions/auth';
import { fetchNavigationData } from '../actions/items';

import SearchBox from './SearchBox';

const selectedType = {
    paddingBottom: 1,
    marginBottom: -1,
    boxShadow: 'inset 0px -3px 0px 0px #FFA400',
};

const selectedTypeText = { color: '#FFA400' };

const selectedSubtype = { backgroundColor: 'white', color: '#002677' };

const mobileLinks = [
    {
        to: 'about',
        text: 'About',
    },
    {
        to: 'contact',
        text: 'Contact',
    },
    {
        to: 'services',
        text: 'Services',
    },
    {
        to: 'lp',
        text: 'Kenco-LP®',
    },
    {
        to: 'products',
        text: 'Products',
    },
    {
        to: 'build',
        text: 'Build-A-Label™',
    },
    {
        to: 'try',
        text: 'Try Kenco®',
    },
    {
        to: 'cart',
        text: 'Cart',
    },
    {
        to: 'pay',
        text: 'Pay',
    },
    {
        to: 'account',
        text: 'Account',
    },
];

export function Header(props) {
    const { currentUser, cart, localCart, history } = props;

    const [type, setType] = useState(null);
    const [subtype, setSubtype] = useState(null);
    const [activeHamburger, setActiveHamburger] = useState(false);

    const configurator = useSelector((state) => state.configurator);
    const navigation = useSelector((state) => state.items);

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(clearAuth());
        dispatch(logOut());
        dispatch(fetchNavigationData());

        history.push('/');
    };

    const createBottomContents = () => {
        const order = [
            'Kenco',
            'Primera',
            'GoDEX',
            'Epson',
            'Zebra',
            'UniNet',
            'Other',
        ];
        if (configurator.data) {
            return configurator.data.manufacturers.reduce(
                (content, manufacturer) => {
                    let { manufacturer: name } = manufacturer;
                    const { models, printer: isPrinter } = manufacturer;

                    if (!isPrinter) return content;
                    if (
                        ![
                            'Primera',
                            'GoDEX',
                            'Epson',
                            'UniNet',
                            'Zebra',
                            'Kenco',
                        ].includes(name)
                    ) {
                        name = 'Other';
                    }

                    const printers = configurator.data.printers.filter(
                        (printer) =>
                            models.find(
                                (model) => model.model === printer.model
                            )
                    );

                    content[name] = {
                        title: name,
                        order: order.indexOf(name),
                        style: {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                        },
                        subs: printers
                            .map((printer) => {
                                const { model, compatibility } = printer;
                                const modelData = models.find(
                                    (model) => model.model === printer.model
                                );
                                const customLink = `/build/rolls?mfg=${name}&model=${model}`;

                                return [
                                    {
                                        img: modelData.image,
                                        name: model,
                                        link: '',
                                        categories: [
                                            {
                                                name: 'Custom Labels',
                                                link: customLink,
                                            },
                                        ],
                                        compatibility,
                                    },
                                ];
                            })
                            .sort((a, b) => a[0].name.localeCompare(b[0].name)),
                    };

                    return content;
                },
                {}
            );
        }

        return null;
    };

    const createFullData = () => {
        if (data && bottomContents) {
            const newData = { ...data };
            newData.labels.bottom.contents = bottomContents;

            return newData;
        }

        return {};
    };

    const data = createDropdownData(
        navigation,
        { handleLogout },
        !currentUser.anonymous
    );

    const bottomContents = createBottomContents();
    const fullData = createFullData();

    const renderLeft = (columnCount = 1) => {
        return (
            <div className="nheader-dropdown-left">
                {['top', 'bottom'].map((position) => {
                    if (
                        fullData &&
                        fullData[type] &&
                        fullData[type][position] &&
                        fullData[type][position].contents
                    ) {
                        return (
                            <Fragment key={position}>
                                {fullData[type][position].name && (
                                    <div className="nheader-dropdown-left-subtitle">
                                        {fullData[type][position].name}
                                    </div>
                                )}
                                <ul
                                    className="nheader-dropdown-choices"
                                    style={{ columnCount }}
                                >
                                    {Object.entries(
                                        fullData[type][position].contents
                                    )
                                        .sort((a, b) => a[1].order - b[1].order)
                                        .map((array) => (
                                            <div
                                                key={array[0]}
                                                className="nheader-dropdown-choice"
                                                style={{
                                                    ...array[1].style,
                                                    ...(subtype === array[0]
                                                        ? selectedSubtype
                                                        : {}),
                                                }}
                                                role="button"
                                            >
                                                {(() => {
                                                    if (array[1]) {
                                                        if (array[1].function) {
                                                            return (
                                                                <button
                                                                    className="no-button blue orange condensed"
                                                                    onClick={
                                                                        array[1]
                                                                            .function
                                                                    }
                                                                >
                                                                    {
                                                                        array[1]
                                                                            .title
                                                                    }
                                                                </button>
                                                            );
                                                        }

                                                        if (
                                                            type === 'labels' &&
                                                            !array[1].link
                                                        ) {
                                                            return (
                                                                <button className="no-button condensed">
                                                                    {
                                                                        array[1]
                                                                            .title
                                                                    }
                                                                </button>
                                                            );
                                                        }

                                                        if (array[1].link) {
                                                            return (
                                                                <Link
                                                                    to={
                                                                        array[1]
                                                                            .link
                                                                    }
                                                                >
                                                                    {
                                                                        array[1]
                                                                            .title
                                                                    }
                                                                </Link>
                                                            );
                                                        }

                                                        return (
                                                            <span>
                                                                {array[1].title}
                                                            </span>
                                                        );
                                                    }
                                                })()}
                                                {array[1].badge && (
                                                    <Chip
                                                        color="secondary"
                                                        size="small"
                                                        label={array[1].badge}
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            marginLeft: 8,
                                                            marginTop: -1,
                                                            transform:
                                                                'scale(0.75)',
                                                            fontWeight: 400,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </ul>
                            </Fragment>
                        );
                    }

                    return '';
                })}
            </div>
        );
    };

    return (
        <div className="nheader">
            <nav className="nheader-contents">
                <div style={{ position: 'relative' }}>
                    <Link to="/">
                        <img
                            src={logo}
                            alt="Kenco logo"
                            className="nheader-logo"
                        />
                    </Link>
                    <div
                        style={{
                            position: 'absolute',
                            marginTop: -20,
                            marginLeft: 15,
                        }}
                    >
                        <a href={`tel:${TEL_NO}`}>
                            <span style={{ fontSize: 10 }}>
                                {formatTelephone(TEL_NO)}
                            </span>
                        </a>
                    </div>
                </div>
                <div
                    className="upper-header-hamburger hide-small hide-medium hide-big"
                    onClick={() => setActiveHamburger(!activeHamburger)}
                >
                    {activeHamburger ? (
                        <i className="fas fa-times hamburger" />
                    ) : (
                        <i className="fas fa-bars hamburger" />
                    )}
                </div>
                {activeHamburger && (
                    <ul className="nheader-mobile-links hide-medium hide-big">
                        {mobileLinks.map((mobileLink) => (
                            <li
                                className="nheader-mobile-link"
                                key={mobileLink.to}
                            >
                                <Link
                                    to={`/${mobileLink.to}`}
                                    onClick={() => setActiveHamburger(false)}
                                >
                                    <div className="nheader-mobile-link-title">
                                        {mobileLink.text}
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
                <ul className="nheader-links hide-mobile">
                    {/* <li
                            onMouseEnter={() => {
                                setType('labels');
                                if (!position && !subtype) {
                                    setPosition('top');
                                    setSubtype('rolls');
                                }
                            }}
                            onMouseLeave={() => setType(null)}
                            className="nheader-link"
                            style={type === 'labels' ? selectedType : {}}
                        >
                            <Link
                                to="/products"
                                className="nheader-link-a"
                                style={
                                    type === 'labels' ? selectedTypeText : {}
                                }
                            >
                                Labels &amp; Tags
                            </Link>
                            <Fade in={type === 'labels'} mountOnEnter>
                                <div className="nheader-dropdown">
                                    {renderLeft()}
                                    <div className="nheader-dropdown-right">
                                        <div className="nheader-dropdown-display">
                                            {renderRight()}
                                            {quantity > 20 && (
                                                <Pagination
                                                    page={page}
                                                    count={Math.ceil(
                                                        quantity / 20
                                                    )}
                                                    shape="rounded"
                                                    onChange={(e, page) =>
                                                        setPage(page)
                                                    }
                                                    style={{ width: 500 }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </li> */}
                    <li
                        onMouseEnter={() => setType('about')}
                        onMouseLeave={() => setType(null)}
                        className="nheader-link"
                        style={type === 'about' ? selectedType : {}}
                    >
                        <Link to="/about" className="nheader-link-a">
                            ABOUT
                        </Link>
                        <Fade in={type === 'about'} mountOnEnter>
                            <div
                                className="nheader-dropdown-left-only"
                                onMouseLeave={() => setSubtype(null)}
                            >
                                {renderLeft()}
                            </div>
                        </Fade>
                    </li>
                    <li
                        onMouseEnter={() => setType('all')}
                        onMouseLeave={() => setType(null)}
                        className="nheader-link"
                        style={type === 'all' ? selectedType : {}}
                    >
                        <Link
                            to="/all-products"
                            className="nheader-link-a"
                            style={type === 'all' ? selectedTypeText : {}}
                        >
                            PRODUCTS
                        </Link>
                        <Fade in={type === 'all'} mountOnEnter>
                            <div
                                className="nheader-dropdown-left-only"
                                onMouseLeave={() => setSubtype(null)}
                            >
                                {renderLeft(2)}
                            </div>
                        </Fade>
                    </li>
                    {currentUser &&
                        currentUser.email.endsWith('kencolabel.com') && (
                            <li
                                onMouseEnter={() => setType('brands')}
                                onMouseLeave={() => setType(null)}
                                className="nheader-link"
                                style={type === 'brands' ? selectedType : {}}
                            >
                                <Link
                                    to="/"
                                    className="nheader-link-a"
                                    style={
                                        type === 'brands'
                                            ? selectedTypeText
                                            : {}
                                    }
                                >
                                    BRANDS
                                </Link>
                                <Fade in={type === 'brands'} mountOnEnter>
                                    <div
                                        className="nheader-dropdown-left-only"
                                        onMouseLeave={() => setSubtype(null)}
                                    >
                                        {renderLeft(2)}
                                    </div>
                                </Fade>
                            </li>
                        )}
                    <li
                        onMouseEnter={() => setType('build')}
                        onMouseLeave={() => setType(null)}
                        className="nheader-link"
                        style={type === 'build' ? selectedType : {}}
                    >
                        <Link
                            to="/build"
                            className="nheader-link-a"
                            style={type === 'build' ? selectedTypeText : {}}
                        >
                            BUILD-A-LABEL™
                        </Link>
                        <Fade in={type === 'build'} mountOnEnter>
                            <div
                                className="nheader-dropdown-left-only"
                                onMouseLeave={() => setSubtype(null)}
                            >
                                {renderLeft()}
                            </div>
                        </Fade>
                    </li>
                    <li
                        onMouseEnter={() => setType('contact')}
                        onMouseLeave={() => setType(null)}
                        className="nheader-link"
                        style={type === 'contact' ? selectedType : {}}
                    >
                        <Link to="/contact" className="nheader-link-a">
                            CONTACT US
                        </Link>
                        <Fade in={type === 'contact'} mountOnEnter>
                            <div
                                className="nheader-dropdown-left-only"
                                onMouseLeave={() => setSubtype(null)}
                            >
                                {renderLeft()}
                            </div>
                        </Fade>
                    </li>
                    {/* <li className="nheader-link">
                            <Link to="/cart" className="nheader-link-a">
                                Cart{' '}
                                <span
                                    style={{
                                        display: 'inline-block',
                                        marginLeft: 5,
                                    }}
                                >
                                    {renderCartBubble()}
                                </span>
                            </Link>
                        </li>
                        {showPay && (
                            <li className="nheader-link">
                                <Link to="/pay" className="nheader-link-a">
                                    Pay
                                </Link>
                            </li>
                        )}
                        {loggedIn && (
                            <li className="nheader-link">
                                <Link to="/account" className="nheader-link-a">
                                    Account
                                </Link>
                            </li>
                        )} */}
                    <li
                        className="nheader-link"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <SearchBox isMini />
                    </li>
                    <li
                        className="nheader-link"
                        style={{
                            ...(type === 'account' &&
                            currentUser &&
                            !currentUser.anonymous
                                ? selectedType
                                : {}),
                        }}
                        onMouseEnter={() => {
                            if (currentUser && !currentUser.anonymous)
                                setType('account');
                        }}
                        onMouseLeave={() => setType(null)}
                    >
                        {currentUser && !currentUser.anonymous ? (
                            <>
                                <Link to="/account">
                                    <div>
                                        <div style={{ fontSize: 12 }}>
                                            HELLO,{' '}
                                            {currentUser.firstName.toUpperCase()}
                                        </div>
                                    </div>
                                    <div style={{ fontSize: '1rem' }}>
                                        ACCOUNT
                                    </div>
                                </Link>
                            </>
                        ) : (
                            <Link to="/login">
                                <div className="nheader-link-a">
                                    <i className="fas fa-sign-in-alt"></i> LOG
                                    IN
                                </div>
                            </Link>
                        )}
                        <Fade in={type === 'account'} mountOnEnter>
                            <div
                                className="nheader-dropdown-left-only"
                                onMouseLeave={() => setSubtype(null)}
                                style={{ marginTop: 14 }}
                            >
                                {renderLeft()}
                            </div>
                        </Fade>
                    </li>
                    <div
                        style={{ marginLeft: 20 }}
                        className="nheader-link-cart"
                    >
                        <Link to="/cart">
                            <div
                                style={{
                                    display: 'flex',
                                    flexFlow: 'column nowrap',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="nheader-link-cart-bubble">
                                    {cart && cart.contents
                                        ? cart.contents.length
                                        : localCart.length}
                                </div>
                                <div style={{ fontSize: '1.75rem' }}>
                                    <i className="fas fa-shopping-cart"></i>
                                </div>
                            </div>
                        </Link>
                    </div>
                </ul>
            </nav>
        </div>
    );
}

export default withRouter(Header);
