/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import store from './store';
import App from './Components/App';
import { unregister } from './registerServiceWorker';

import './index.css';

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: 'IBM Plex Sans',
        fontFamilyNarrow: 'IBM Plex Sans Condensed',
        monospace: 'IBM Plex Mono',
    },
    palette: {
        primary: {
            light: '#E6E8F3',
            main: '#002677',
        },
        secondary: {
            light: '#FCD3C8',
            main: '#DB4924',
        },
        tertiary: {
            light: '#D9EAE1',
            main: '#73BA9B',
        },
        tan: {
            main: '#E6E1DC',
        },
        background: {
            paper: '#FFF',
        },
    },
});

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <MuiThemeProvider theme={theme}>
                <App />
            </MuiThemeProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);

unregister();
