import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formatCategoryName } from '../scripts/functions';

// import './styling/Autocomplete.css';

export default function Autocomplete(props) {
    const createMatchesList = () => {
        const { matches, selectedOption } = props;

        const matchesList = [];
        let counter = 0;
        const createListItems = (matches, list, key) =>
            matches[key].forEach((match) => {
                const content = (
                    <div id={`match-div-${match.id}`} className="match-div">
                        <span
                            id={`match-title-span-${match.id}`}
                            className="match-title-span"
                        >
                            {match.name}
                        </span>
                        <span className="match-type-span"> in </span>
                        {_.capitalize(key)}
                    </div>
                );

                list.push(
                    <li
                        key={match.id}
                        className={`match-li ${
                            counter === selectedOption ? 'selected' : ''
                        }`}
                    >
                        {match.external ? (
                            <a
                                className="match-link"
                                href={match.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {content}
                            </a>
                        ) : (
                            <Link
                                className="match-link"
                                to={
                                    match.link ||
                                    `/${
                                        key === 'categories' ? 'category' : key
                                    }/${formatCategoryName(match.name)}`
                                }
                            >
                                {content}
                            </Link>
                        )}
                    </li>
                );
                counter++;
            });

        for (const _key in matches) {
            createListItems(matches, matchesList, _key, counter);
        }

        return matchesList.slice(0, 10);
    };

    return (
        <div className="autocomplete-container">
            <ul className="autocomplete-matches">{createMatchesList()}</ul>
        </div>
    );
}
