export const loadAuthToken = () => localStorage.getItem('authToken');

export const saveAuthToken = (authToken) => {
	try {
		localStorage.setItem('authToken', authToken);
	} catch (e) {
		// Could not set auth token.
	}
};

export const clearAuthToken = () => {
	try {
		localStorage.removeItem('authToken');
	} catch (e) {
		// Could not remove auth token.
	}
};

export const loadLocalCart = () => {
	const cart = JSON.parse(localStorage.getItem('cart'));

	if (!cart) {
		try {
			localStorage.setItem('cart', JSON.stringify([]));
		} catch (e) {
			// Could not initialize cart.
		}
	}

	return cart;
};

export const saveLocalCart = (localCart) => {
	try {
		localStorage.setItem('cart', JSON.stringify(localCart));
	} catch (e) {
		// Could not save cart.
	}
};

export const clearLocalCart = () => {
	try {
		localStorage.removeItem('cart');
	} catch (e) {
		// Could not remove cart.
	}
};
