import _ from 'lodash';
import moment from 'moment';

import { API_BASE_URL } from '../config';
import store from '../store';
import { emptyCart } from './carts';
import { clearLocalCart } from './local-cart';
import { loadAffiliates } from '../session-storage';
import {
    recordTransaction,
    recordReferralTransaction,
} from '../scripts/tracking';

export const FETCH_ORDER_DATA_REQUEST = 'FETCH_ORDER_DATA_REQUEST';
const fetchOrderDataRequest = () => ({
    type: FETCH_ORDER_DATA_REQUEST,
});

export const FETCH_ORDER_DATA_ERROR = 'FETCH_ORDER_DATA_ERROR';
const fetchOrderDataError = (error) => ({
    type: FETCH_ORDER_DATA_ERROR,
    error,
});

export const FETCH_ORDER_DATA_SUCCESS = 'FETCH_ORDER_DATA_SUCCESS';
const fetchOrderDataSuccess = (data) => ({
    type: FETCH_ORDER_DATA_SUCCESS,
    data,
});

export const FETCH_INVOICE_DATA_REQUEST = 'FETCH_INVOICE_DATA_REQUEST';
const fetchInvoiceDataRequest = () => ({
    type: FETCH_INVOICE_DATA_REQUEST,
});

export const FETCH_INVOICE_DATA_ERROR = 'FETCH_INVOICE_DATA_ERROR';
const fetchInvoiceDataError = (error) => ({
    type: FETCH_INVOICE_DATA_ERROR,
    error,
});

export const FETCH_INVOICE_DATA_SUCCESS = 'FETCH_INVOICE_DATA_SUCCESS';
const fetchInvoiceDataSuccess = (data) => ({
    type: FETCH_INVOICE_DATA_SUCCESS,
    data,
});

export const FETCH_SINGLE_INVOICE_DATA_SUCCESS =
    'FETCH_SINGLE_INVOICE_DATA_SUCCESS';
const fetchSingleInvoiceDataSuccess = (data) => ({
    type: FETCH_SINGLE_INVOICE_DATA_SUCCESS,
    data,
});

export const POST_ORDER_DATA_SUCCESS = 'POST_ORDER_DATA_SUCCESS';
const postOrderDataSuccess = () => ({
    type: POST_ORDER_DATA_SUCCESS,
});

export const POST_ORDER_DATA_REQUEST = 'POST_ORDER_DATA_REQUEST';
const postOrderDataRequest = () => ({
    type: POST_ORDER_DATA_REQUEST,
});

export const POST_ORDER_DATA_ERROR = 'POST_ORDER_DATA_ERROR';
const postOrderDataError = (error) => ({
    type: POST_ORDER_DATA_ERROR,
    error,
});

export const fetchOrderData = () => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(fetchOrderDataRequest());
    fetch(`${API_BASE_URL}/orders/all`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) => res.json())
        .then((orders) => dispatch(fetchOrderDataSuccess(orders)))
        .catch((err) => dispatch(fetchOrderDataError(err)));
};

export const fetchInvoiceData = (authToken, customerID) => (dispatch) => {
    dispatch(fetchInvoiceDataRequest());
    fetch(`${API_BASE_URL}/invoice/${customerID}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) => res.json())
        .then((invoiceData) => {
            const { invoices: rawInvoices } = invoiceData;
            const invoices = rawInvoices.map((invoice) => {
                const { dueDate, balance, origAmt } = invoice;

                const status = (() => {
                    if (!balance) return 'paid';
                    if (moment(dueDate).isBefore(moment(), 'd'))
                        return 'overdue';
                    if (origAmt > balance) return 'partial';

                    return 'unpaid';
                })();

                return { ...invoice, status };
            });

            dispatch(fetchInvoiceDataSuccess(invoices));
        })
        .catch((err) => dispatch(fetchInvoiceDataError(err)));
};

export const fetchSingleInvoiceData = (invoiceID, orderID) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(fetchInvoiceDataRequest());
    fetch(`${API_BASE_URL}/find/invoice/${invoiceID}/${orderID}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) => res.json())
        .then((invoiceData) => {
            const { invoice: rawInvoice } = invoiceData;

            const { dueDate, balance, origAmt } = rawInvoice || {};

            const status = (() => {
                if (!balance) return 'paid';
                if (moment(dueDate).isBefore(moment(), 'd')) return 'overdue';
                if (origAmt > balance) return 'partial';

                return 'unpaid';
            })();

            const invoice = { ...rawInvoice, status };

            dispatch(fetchSingleInvoiceDataSuccess(invoice));
        })
        .catch((err) => dispatch(fetchInvoiceDataError(err)));
};

export const postOrderData = (data) => (dispatch) => {
    const { authToken } = store.getState().auth;
    const {
        billingAddress,
        shippingAddress,
        shippingOptions,
        terms,
        lastFour,
        month,
        year,
        ccType,
        subtotal,
        nri,
        shipping,
        tax,
        total,
        po,
        comment,
        contents,
        lineItems,
        confirmation,
        emailAddress,
        customerID,
        status,
        coupon,
        discounted,
        isPayPal,
        payPalId,
        payPalAuthorizationId,
        paidShipping,
    } = data;

    const quantityUpdates = [];
    const affiliates = loadAffiliates();

    let newDiscounted = 0;

    const newOrder = {
        status,
        confirmation,
        customerID,
        emailAddress,
        billingAddress,
        shippingAddress,
        terms,
        payment:
            !terms && !isPayPal
                ? {
                      lastFour,
                      ccType,
                      expiration: { month, year },
                  }
                : null,
        items: contents.map((itemObject) => {
            const { item, quantity } = itemObject;
            const {
                sku,
                name,
                images,
                path: paths,
                quantityOnHand,
                mfgData,
            } = item;
            const {
                unitPrice,
                itemTotal: price,
                updateQuantity,
                itemDiscount,
                quantityFree,
            } = lineItems[sku];

            if (updateQuantity && quantityOnHand) {
                quantityUpdates.push({
                    sku,
                    quantityOnHand: quantityOnHand - quantity,
                });
            }

            const thumbnailObj = images.find((image) => image.thumbnail);
            const thumbnail = thumbnailObj
                ? thumbnailObj.imageFile
                : images[0].imageFile;

            newDiscounted += (quantityFree || 0) * unitPrice;

            return {
                sku,
                name,
                quantity: quantity + (quantityFree || 0),
                unitPrice,
                price,
                itemDiscount,
                quantityFree,
                thumbnail,
                path: paths[0],
                mfgData,
            };
        }),
        subtotal,
        tax,
        shipping,
        nri,
        total,
        shippingOptions,
        po,
        comment,
        invoices: [],
        coupon: coupon ? _.omit(coupon, 'redeemed', 'uses') : null,
        discounted: newDiscounted || discounted,
        quantityUpdates,
        affiliates,
        isPayPal,
        payPalId,
        payPalAuthorizationId,
        paidShipping,
    };

    dispatch(postOrderDataRequest());
    fetch(`${API_BASE_URL}/orders/new`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ newOrder }),
    })
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then((order) => {
            recordTransaction(order);
            recordReferralTransaction(order);
            dispatch(emptyCart());
            dispatch(postOrderDataSuccess());
        })
        .catch((error) => dispatch(postOrderDataError(error)));
};

export const postAnonymousOrderData = (data) => (dispatch) => {
    const {
        billingAddress,
        shippingAddress,
        shippingOptions,
        terms,
        lastFour,
        month,
        year,
        ccType,
        subtotal,
        nri,
        shipping,
        tax,
        total,
        po,
        comment,
        contents,
        lineItems,
        confirmation,
        emailAddress,
        customerID,
        status,
        coupon,
        discounted,
        isPayPal,
        payPalId,
        payPalAuthorizationId,
    } = data;

    const quantityUpdates = [];
    const affiliates = loadAffiliates();

    const newOrder = {
        status,
        confirmation,
        customerID,
        emailAddress,
        billingAddress,
        shippingAddress,
        terms,
        payment:
            !terms && !isPayPal
                ? {
                      lastFour,
                      ccType,
                      expiration: { month, year },
                  }
                : null,
        items: contents.map((itemObject) => {
            const { item, quantity } = itemObject;
            const { sku, name, images, path: paths, quantityOnHand } = item;
            const {
                unitPrice,
                itemTotal: price,
                updateQuantity,
            } = lineItems[sku];

            if (updateQuantity && quantityOnHand) {
                quantityUpdates.push({
                    sku,
                    quantityOnHand: quantityOnHand - quantity,
                });
            }

            const thumbnailObj = images.find((image) => image.thumbnail);
            const thumbnail = thumbnailObj
                ? thumbnailObj.imageFile
                : images[0].imageFile;

            return {
                sku,
                name,
                quantity,
                unitPrice,
                price,
                thumbnail,
                path: paths[0],
            };
        }),
        subtotal,
        tax,
        shipping,
        nri,
        total,
        shippingOptions,
        po,
        comment,
        invoices: [],
        coupon,
        discounted,
        quantityUpdates,
        affiliates,
        isPayPal,
        payPalId,
        payPalAuthorizationId,
    };

    dispatch(postOrderDataRequest());
    fetch(`${API_BASE_URL}/orders/new/anonymous`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ newOrder }),
    })
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then(() => {
            dispatch(postOrderDataSuccess());
            dispatch(clearLocalCart());
        })
        .catch((error) => dispatch(postOrderDataError(error)));
};

export const postAmazonOrderData = (data) => (dispatch) => {
    const {
        billingAddress,
        shippingAddress,
        shippingOptions,
        terms,
        subtotal,
        nri,
        shipping,
        tax,
        total,
        po,
        comment,
        contents,
        lineItems,
        confirmation,
        emailAddress,
        customerID,
        status,
        coupon,
        discounted,
        isAmazon,
        orderReferenceId,
    } = data;

    const quantityUpdates = [];
    const affiliates = loadAffiliates();

    const newOrder = {
        status,
        confirmation,
        customerID,
        emailAddress,
        billingAddress,
        shippingAddress,
        terms,
        payment: null,
        items: contents.map((itemObject) => {
            const { item, quantity } = itemObject;
            const { sku, name, images, path: paths, quantityOnHand } = item;
            const {
                unitPrice,
                itemTotal: price,
                updateQuantity,
            } = lineItems[sku];

            if (updateQuantity && quantityOnHand) {
                quantityUpdates.push({
                    sku,
                    quantityOnHand: quantityOnHand - quantity,
                });
            }

            const thumbnailObj = images.find((image) => image.thumbnail);
            const thumbnail = thumbnailObj
                ? thumbnailObj.imageFile
                : images[0].imageFile;

            return {
                sku,
                name,
                quantity,
                unitPrice,
                price,
                thumbnail,
                path: paths[0],
            };
        }),
        subtotal,
        tax,
        shipping,
        nri,
        total,
        shippingOptions,
        po,
        comment,
        invoices: [],
        coupon,
        discounted,
        quantityUpdates,
        affiliates,
        isAmazon,
        orderReferenceId,
    };

    dispatch(postOrderDataRequest());
    fetch(`${API_BASE_URL}/orders/new/amazon`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ newOrder }),
    })
        .then(async (res) => {
            const json = await res.json();

            if (!res.ok) {
                return Promise.reject(json.message);
            }

            return json;
        })
        .then(() => {
            dispatch(postOrderDataSuccess());
            dispatch(emptyCart());
            dispatch(clearLocalCart());
        })
        .catch((error) => dispatch(postOrderDataError(error)));
};

export const updateOrderStatus = (orderID, status) => (dispatch) => {
    const { authToken } = store.getState().auth;

    dispatch(fetchOrderDataRequest());
    fetch(`${API_BASE_URL}/orders/update`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ orderID, status }),
    })
        .then((res) => res.json())
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res.message);
            }

            return res.json();
        })
        .then(() => {
            dispatch(postOrderDataSuccess());
            dispatch(fetchOrderData());
        })
        .catch((error) => dispatch(fetchOrderDataError(error)));
};
