import {
    FETCH_NAVIGATION_DATA_REQUEST,
    FETCH_NAVIGATION_DATA_ERROR,
    FETCH_NAVIGATION_DATA_SUCCESS,
    FETCH_ITEMS_FROM_CATEGORIES_DATA_REQUEST,
    FETCH_ITEMS_FROM_CATEGORIES_DATA_ERROR,
    FETCH_ITEMS_FROM_CATEGORIES_DATA_SUCCESS,
    FETCH_ITEMS_FROM_CATEGORIES_DATA_CLEAR,
    FETCH_GROUP_DATA_REQUEST,
    FETCH_GROUP_DATA_ERROR,
    FETCH_GROUP_DATA_SUCCESS,
    FETCH_VARIATION_DATA_REQUEST,
    FETCH_VARIATION_DATA_ERROR,
    FETCH_VARIATION_DATA_SUCCESS,
} from '../actions/items';

import {
    FETCH_SEARCH_DATA_REQUEST,
    FETCH_SEARCH_DATA_ERROR,
    FETCH_SEARCH_DATA_SUCCESS,
    FETCH_SEARCH_DATA_CLEAR,
} from '../actions/search';

const initialState = {
    brands: [],
    categories: [],
    items: [],
    related: null,
    variations: null,
    loading: false,
    error: true,
    noResults: false,
};

export default (state = initialState, action) => {
    if (action.type === FETCH_NAVIGATION_DATA_REQUEST) {
        return Object.assign({}, state, {
            loading: true,
        });
    }
    if (action.type === FETCH_NAVIGATION_DATA_ERROR) {
        return Object.assign({}, state, {
            loading: false,
            error: action.error,
        });
    }
    if (action.type === FETCH_NAVIGATION_DATA_SUCCESS) {
        const { brands, categories } = action.data;
        return Object.assign({}, state, {
            loading: false,
            brands,
            categories,
        });
    }
    if (action.type === FETCH_GROUP_DATA_REQUEST) {
        return Object.assign({}, state, {
            loading: true,
            related: null,
        });
    }
    if (action.type === FETCH_GROUP_DATA_ERROR) {
        return Object.assign({}, state, {
            loading: false,
            error: action.error,
        });
    }
    if (action.type === FETCH_GROUP_DATA_SUCCESS) {
        return Object.assign({}, state, {
            loading: false,
            related: action.data,
        });
    }
    if (action.type === FETCH_VARIATION_DATA_REQUEST) {
        return Object.assign({}, state, {
            loading: true,
            variations: null,
        });
    }
    if (action.type === FETCH_VARIATION_DATA_ERROR) {
        return Object.assign({}, state, {
            loading: false,
            error: action.error,
        });
    }
    if (action.type === FETCH_VARIATION_DATA_SUCCESS) {
        return Object.assign({}, state, {
            loading: false,
            variations: action.data,
        });
    }
    if (
        action.type === FETCH_ITEMS_FROM_CATEGORIES_DATA_REQUEST ||
        action.type === FETCH_SEARCH_DATA_REQUEST
    ) {
        return Object.assign({}, state, {
            loading: true,
            noResults: false,
        });
    }
    if (
        action.type === FETCH_ITEMS_FROM_CATEGORIES_DATA_ERROR ||
        action.type === FETCH_SEARCH_DATA_ERROR
    ) {
        const { error } = action;
        return Object.assign({}, state, {
            loading: false,
            error,
        });
    }
    if (action.type === FETCH_ITEMS_FROM_CATEGORIES_DATA_SUCCESS) {
        const items = action.data;
        const { noResults } = action;
        return Object.assign({}, state, {
            loading: false,
            items,
            noResults,
        });
    }
    if (action.type === FETCH_SEARCH_DATA_SUCCESS) {
        const items = action.data;
        const { noResults } = action;
        return Object.assign({}, state, {
            loading: false,
            items,
            noResults,
        });
    }
    if (
        action.type === FETCH_ITEMS_FROM_CATEGORIES_DATA_CLEAR ||
        action.type === FETCH_SEARCH_DATA_CLEAR
    ) {
        return Object.assign({}, state, {
            items: [],
        });
    }

    return state;
};
