import { API_BASE_URL } from '../config';
import store from '../store';
import { clearLocalCart } from './local-cart';

export const FETCH_CART_DATA_REQUEST = 'FETCH_CART_DATA_REQUEST';
const fetchCartDataRequest = () => ({
	type: FETCH_CART_DATA_REQUEST,
});

export const FETCH_CART_DATA_ERROR = 'FETCH_CART_DATA_ERROR';
const fetchCartDataError = error => ({
	type: FETCH_CART_DATA_ERROR,
	error,
});

export const FETCH_CART_DATA_SUCCESS = 'FETCH_CART_DATA_SUCCESS';
const fetchCartDataSuccess = data => ({
	type: FETCH_CART_DATA_SUCCESS,
	data,
});

export const createCartData = () => (dispatch) => {
	const { authToken } = store.getState().auth;

	dispatch(fetchCartDataRequest());
	fetch(`${API_BASE_URL}/carts/create`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	}).then((res) => {
		if (!res.ok) {
			return Promise.reject(res.message);
		}

		return res.json();
	}).then(cart => dispatch(fetchCartDataSuccess(cart)))
		.catch(error => dispatch(fetchCartDataError(error)));
};

export const postCartData = (item, quantity) => (dispatch) => {
	const { authToken } = store.getState().auth;

	dispatch(fetchCartDataRequest());
	fetch(`${API_BASE_URL}/carts/update`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${authToken}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		body: JSON.stringify({
			item,
			quantity,
		}),
	}).then((res) => {
		if (!res.ok) {
			return Promise.reject(res.message);
		}

		return res.json();
	}).then(cart => dispatch(fetchCartDataSuccess(cart)))
		.catch(error => dispatch(fetchCartDataError(error)));
};

export const postMultipleCartData = itemObjects => async (dispatch) => {
	const { authToken } = store.getState().auth;

	dispatch(fetchCartDataRequest());
	const posts = [];

	for (let i = 0; i < itemObjects.length; i++) {
		const { item, quantity } = itemObjects[i];

		posts.push(fetch(`${API_BASE_URL}/carts/update`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authToken}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({
				item,
				quantity,
			}),
		}));
	}

	await Promise.all(posts);

	dispatch(fetchCartData());
};

export const emptyCart = () => (dispatch) => {
	const { authToken } = store.getState().auth;

	if (authToken) {
		dispatch(fetchCartDataRequest());
		fetch(`${API_BASE_URL}/carts/complete`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authToken}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		}).then(res => res.json())
			.then(cart => dispatch(fetchCartDataSuccess(cart)))
			.catch(error => dispatch(fetchCartDataError(error)));
	}
};

export const integrateCarts = () => (dispatch) => {
	const { authToken } = store.getState().auth;
	const { localCart } = store.getState().localCart;

	dispatch(fetchCartDataRequest());
	return Promise.all(localCart.map((cartItem) => {
		const { item, quantity } = cartItem;

		return fetch(`${API_BASE_URL}/carts/update`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${authToken}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({
				item,
				quantity,
			}),
		}).then((res) => {
			if (!res.ok) {
				return Promise.reject(res.message);
			}

			return res.json();
		});
	})).then(() => dispatch(clearLocalCart()))
		.then(() => dispatch(fetchCartData()))
		.catch(err => dispatch(fetchCartDataError(err)));
};

export const deleteCartData = sku => (dispatch) => {
	const { authToken } = store.getState().auth;

	dispatch(fetchCartDataRequest());
	fetch(`${API_BASE_URL}/carts/delete`, {
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${authToken}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		body: JSON.stringify({
			sku,
		}),
	}).then((res) => {
		if (!res.ok) {
			return Promise.reject(res.message);
		}

		return res.json();
	}).then(cart => dispatch(fetchCartDataSuccess(cart)))
		.catch(error => dispatch(fetchCartDataError(error)));
};

export const fetchCartData = () => (dispatch) => {
	const { authToken } = store.getState().auth;

	dispatch(fetchCartDataRequest());
	fetch(`${API_BASE_URL}/carts`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	}).then((res) => {
		if (!res.ok) {
			return Promise.reject(res.message);
		}
		return res.json();
	}).then((cart) => {
		if (cart) {
			return dispatch(fetchCartDataSuccess(cart));
		}

		return dispatch(createCartData());
	})
		.catch(error => dispatch(fetchCartDataSuccess(error)));
};
