import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';

import ProductBox from './ProductBox';
import Filter from './Filter';

import { fetchSearchData, fetchSearchDataClear } from '../actions/search';
import { recordSearch } from '../scripts/tracking';

// import './styling/SearchPage.css';

const styles = () => ({
    progress: {
        position: 'absolute',
        marginTop: '-0.25em',
        marginLeft: '-2em',
    },
});

class SearchPage extends React.Component {
    state = {
        currentQuery: '',
        shouldRender: false,
        showQty: 50,
        filteredItems: [],
        reset: false,
        showItems: true,
        showLoading: false,
        shouldReset: false,
    };

    componentDidMount = () => {
        const { dispatch } = this.props;

        dispatch(fetchSearchDataClear());

        this.timeout = setTimeout(
            () => this.setState({ shouldRender: true }),
            100
        );

        this.handleQuery();
    };

    componentDidUpdate = (prevProps) => {
        const { history, items } = this.props;
        const {
            currentQuery,
            shouldRender,
            filteredItems,
            reset,
            shouldReset,
        } = this.state;

        this.handleQuery();

        if (
            _.isEmpty(items) &&
            shouldRender &&
            this.checkConfigured(currentQuery)
        ) {
            history.push(`/build/parse?sku=${currentQuery.toUpperCase()}`);
        }

        if (_.isEmpty(prevProps.items) && !_.isEmpty(items) && !shouldReset) {
            this.setState({ shouldReset: true });
        }

        if (shouldRender && items.length) {
            if (reset) {
                clearTimeout(this.itemsRef);
                clearTimeout(this.loadingRef);

                this.setState({
                    reset: false,
                    filteredItems: items,
                    showItems: false,
                    showLoading: false,
                });

                this.itemsRef = setTimeout(() => {
                    this.setState({ showItems: true });
                }, 100);

                this.loadingRef = setTimeout(() => {
                    this.setState({ showLoading: true });
                }, 100);
            }

            if (!filteredItems.length) {
                this.setState({ filteredItems: items });
            }
        }
    };

    componentWillUnmount = () => {
        clearTimeout(this.timeout);
        clearTimeout(this.itemsRef);
        clearTimeout(this.loadingRef);
    };

    handleQuery = () => {
        const { history, location, dispatch, currentUser } = this.props;
        const { search } = location;
        const { customerID } = currentUser;

        const { currentQuery } = this.state;

        const query = new URLSearchParams(search);
        const q = query.get('q');

        if (q) {
            if (q !== currentQuery) {
                this.setState({ currentQuery: q }, () => {
                    dispatch(fetchSearchDataClear());
                    dispatch(fetchSearchData(q));
                    recordSearch(q, customerID);
                });
            }
        } else if (!q) {
            history.push('/');
        }
    };

    checkConfigured = (query) => {
        const regExp =
            /^(pri|2pri|ll|13|14|15|34|24|25|35|36|38|CX(?!1[02]00.)){1}[\d.]+/gi;
        return regExp.test(query);
    };

    renderSearchResults = () => {
        const { items, dispatch, loggedIn } = this.props;
        const { shouldRender } = this.state;

        if (!_.isEmpty(items) && shouldRender) {
            return items.map((item) => {
                const { sku } = item;

                return (
                    <ProductBox
                        key={item.sku}
                        item={item}
                        type="item"
                        picture={
                            item.images
                                ? item.images.find((image) => image.thumbnail)
                                      .imageFile
                                : ''
                        }
                        overlay={
                            item.images
                                ? item.images.find((image) => image.thumbnail)
                                      .overlay
                                : ''
                        }
                        url={`/item/${sku}`}
                        open={() => window.scrollTo({ top: 0 })}
                        dispatch={dispatch}
                        loggedIn={loggedIn}
                    />
                );
            });
        }

        return '';
    };

    renderItems = () => {
        const { dispatch, loggedIn } = this.props;
        const { showQty, shouldRender, showItems, showLoading, filteredItems } =
            this.state;

        if (!_.isEmpty(filteredItems) && shouldRender && showItems) {
            return (
                <InfiniteScroll
                    dataLength={
                        showQty > filteredItems.length
                            ? filteredItems.length
                            : showQty
                    } //This is important field to render the next data
                    next={() => {
                        this.setState({ showQty: showQty + 25 });
                    }}
                    hasMore={showQty < filteredItems.length}
                    loader={<h4>Loading...</h4>}
                    style={{
                        display: 'grid',

                        margin: '4rem -40px',
                    }}
                >
                    {filteredItems.slice(0, showQty).map((item) => (
                        <ProductBox
                            key={item.sku}
                            loggedIn={loggedIn}
                            dispatch={dispatch}
                            item={item}
                            type="item"
                            picture={
                                item.images
                                    ? item.images.find(
                                          (image) => image.thumbnail
                                      ).imageFile
                                    : ''
                            }
                            overlay={
                                item.images
                                    ? item.images.find(
                                          (image) => image.thumbnail
                                      ).overlay
                                    : ''
                            }
                            url={`/item/${item.sku}`}
                            open={() => {
                                window.scrollTo({ top: 0 });
                            }}
                        />
                    ))}
                </InfiniteScroll>
            );
        }

        if (showLoading) {
            return (
                <div className="loading-container">
                    <CircularProgress
                        color="primary"
                        style={{ marginRight: '0.5em' }}
                    />
                    <span>Loading...</span>
                </div>
            );
        }

        return '';
    };

    renderHeader = () => {
        const { classes, location, loading, noResults, items } = this.props;
        const { filteredItems } = this.state;
        const { search } = location;

        const query = new URLSearchParams(search);
        const q = query.get('q');

        if (noResults && !loading) {
            return (
                <Fragment>
                    <h1 className="page-header">
                        <span>No results found for </span>
                        <span className="search-string">{q}</span>
                    </h1>
                    <p>
                        <Link to="/" className="bold">
                            Click here
                        </Link>{' '}
                        to return to the homepage.
                    </p>
                </Fragment>
            );
        }
        if (!_.isEmpty(items) && !loading) {
            return (
                <h1 className="page-header">
                    <span>Results for </span>
                    <span className="search-string">{q}</span>
                    {items.length !== filteredItems.length && (
                        <span> with filters applied</span>
                    )}
                    <span> (</span>
                    <span>{filteredItems.length}</span>
                    <span>)</span>
                </h1>
            );
        }

        return (
            <h1 className="page-header">
                <CircularProgress
                    className={classes.progress}
                    size={60}
                    thickness={5}
                />
                <span>Searching for </span>
                <span className="search-string">{q}</span>
                <span>...</span>
            </h1>
        );
    };

    render() {
        const { items } = this.props;
        const { filteredItems, shouldReset } = this.state;

        return (
            <div className="page-container static-container results-page-container search-page-container">
                <Helmet>
                    <title>Search | Kenco Label &amp; Tag</title>
                    <meta
                        name="description"
                        content="Search our catalog of thousands of great products and find exactly what you need. From labels and tags to printers, ink, price guns, and more."
                    />
                </Helmet>
                <div className="search-page">
                    {this.renderHeader()}
                    <div className="hide-mobile">
                        <div className="products-filter-container">
                            <Filter
                                items={filteredItems}
                                setFilteredItems={(f) =>
                                    this.setState({ filteredItems: f })
                                }
                                setReset={(boolean) =>
                                    this.setState({ reset: boolean })
                                }
                                max={items.length}
                                shouldReset={shouldReset}
                                setShouldReset={(boolean) =>
                                    this.setState({ shouldReset: boolean })
                                }
                                hideBrands
                                requireCategoryFilter
                            />
                            {this.renderItems()}
                        </div>
                    </div>
                    <div className="hide-desktop">{this.renderItems()}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { loading, error, items, noResults } = state.items;
    const { currentUser } = state.auth;

    return {
        loggedIn: currentUser && !currentUser.anonymous,
        loading,
        error,
        items,
        noResults,
        currentUser,
    };
};

export default withRouter(
    connect(mapStateToProps)(withStyles(styles)(SearchPage))
);
