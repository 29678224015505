import { API_BASE_URL } from '../config';

export const FETCH_CONFIGURATOR_DATA_REQUEST = 'FETCH_CONFIGURATOR_DATA_REQUEST';
const fetchConfiguratorDataRequest = () => ({
	type: FETCH_CONFIGURATOR_DATA_REQUEST,
});

export const FETCH_CONFIGURATOR_DATA_ERROR = 'FETCH_CONFIGURATOR_DATA_ERROR';
const fetchConfiguratorDataError = error => ({
	type: FETCH_CONFIGURATOR_DATA_ERROR,
	error,
});

export const FETCH_CONFIGURATOR_DATA_SUCCESS = 'FETCH_CONFIGURATOR_DATA_SUCCESS';
const fetchConfiguratorDataSuccess = data => ({
	type: FETCH_CONFIGURATOR_DATA_SUCCESS,
	data,
});

export const fetchConfiguratorData = () => (dispatch) => {
	dispatch(fetchConfiguratorDataRequest());

	// const configuratorData = JSON.parse(sessionStorage.getItem('configuratorData'));

	// if (configuratorData) {
	// 	return dispatch(fetchConfiguratorDataSuccess(configuratorData));
	// }

	return fetch(`${API_BASE_URL}/configurator`)
		.then(res => res.json())
		.then((data) => {
			sessionStorage.setItem('configuratorData', JSON.stringify(data));
			return dispatch(fetchConfiguratorDataSuccess(data));
		})
		.catch(error => dispatch(fetchConfiguratorDataError(error)));
};
