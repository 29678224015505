import {
	FETCH_CART_DATA_REQUEST,
	FETCH_CART_DATA_ERROR,
	FETCH_CART_DATA_SUCCESS,
} from '../actions/carts';

const initialState = {
	loading: false,
	error: false,
	cart: null,
};

export default (state = initialState, action) => {
	if (action.type === FETCH_CART_DATA_REQUEST) {
		return Object.assign({}, state, {
			loading: true,
		});
	} if (action.type === FETCH_CART_DATA_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			error: action.error,
		});
	} if (action.type === FETCH_CART_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			error: null,
			cart: action.data,
		});
	}

	return state;
};
