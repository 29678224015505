import moment from 'moment';

import { loadReferral } from '../session-storage';

const { dataLayer } = window;

const formatProducts = (items) =>
    items.map((item) => {
        const { sku, name, price, quantity, category } = item;

        return {
            name,
            id: sku,
            price,
            quantity,
            category,
        };
    });

export const recordCheckout = (step) => {
    if (process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: { step },
                },
            },
        });
    }
};

// eslint-disable-next-line import/prefer-default-export
export const recordTransaction = (order) => {
    const { orderID, total, tax, shipping, coupon, items } = order;
    const products = formatProducts(items);

    recordCheckout(3);

    try {
        if (process.env.NODE_ENV !== 'development') {
            dataLayer.push('event', 'purchase', {
                transaction_id: orderID,
                // Sum of (price * quantity) for all items.
                value: total,
                tax,
                shipping: shipping,
                currency: 'USD',
                ...(coupon && coupon.couponID
                    ? { coupon: coupon.couponID }
                    : {}),
                items: items.map((item, i) => ({
                    item_id: item.sku,
                    item_name: item.name,
                    item_index: i,
                    item_brand: item.brand,
                    price: item.unitPrice,
                    quantity: item.quantity,
                })),
            });

            dataLayer.push({ OrderTotalAmount: total });

            dataLayer.push({
                event: 'purchase',
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: orderID,
                            revenue: total,
                            tax,
                            shipping,
                            coupon: coupon ? coupon.couponID : '',
                        },
                        products,
                    },
                },
            });

            dataLayer.push({
                event: 'adsPurchase',
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: orderID,
                            revenue: total,
                            tax,
                            shipping,
                            coupon: coupon ? coupon.couponID : '',
                        },
                        products,
                    },
                },
            });
        }
    } catch (e) {
        console.log(e);
        // Could not record transaction
    }
};

export const recordSearch = (searchTerm, customerID = 'anonymous') => {
    const timestamp = moment().format('YYYY-MM-DD');

    if (searchTerm && process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'search',
            searchTerm,
            customerID,
            timestamp,
        });
    }
};

export const recordPopupSuccess = (customerID = 'anonymous') => {
    const timestamp = moment().format('YYYY-MM-DD');

    if (process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'popupSuccess',
            customerID,
            timestamp,
        });
    }
};

export const recordPopupClose = (customerID = 'anonymous') => {
    const timestamp = moment().format('YYYY-MM-DD');

    if (process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'popupClose',
            customerID,
            timestamp,
        });
    }
};

export const recordSubmitEmail = (emailAddress) => {
    const timestamp = moment().format('YYYY-MM-DD');

    if (emailAddress && process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'email',
            emailAddress,
            timestamp,
        });
    }
};

export const recordProductClick = (product) => {
    if (process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'productClick',
            sku: product.sku,
            ecommerce: {
                detail: {
                    products: formatProducts([product]),
                },
            },
        });
    }
};

export const recordProductAdd = (product, quantity) => {
    if (process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'addToCart',
            sku: product.sku,
            ecommerce: {
                add: {
                    products: formatProducts([{ ...product, quantity }]),
                },
            },
        });
    }
};

export const recordConfiguration = (sku) => {
    if (process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'configure',
            sku,
        });
    }
};

export const recordSignup = (email, firstName, lastName) => {
    if (process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'signup',
            emailAddress: email,
            fullName: `${firstName} ${lastName}`,
        });
    }
};

export const recordReferral = (emailAddress, source, referralType) => {
    if (source === 'email' && process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'emailReferral',
            emailAddress,
            referralType,
        });
    }
};

export const recordReferralTransaction = (order) => {
    const { total: orderValue, emailAddress, orderID } = order;
    const { source, type: referralType } = loadReferral() || {};

    if (source === 'email' && process.env.NODE_ENV !== 'development') {
        dataLayer.push({
            event: 'referralPurchase',
            orderID,
            emailAddress,
            referralType,
            orderValue,
        });
    }
};
