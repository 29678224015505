import moment from 'moment';

import { API_BASE_URL } from '../config';
import store from '../store';

export const FETCH_INVOICE_DATA_REQUEST = 'FETCH_INVOICE_DATA_REQUEST';
export const fetchInvoiceDataRequest = () => ({
	type: FETCH_INVOICE_DATA_REQUEST
});

export const FETCH_INVOICE_DATA_ERROR = 'FETCH_INVOICE_DATA_ERROR';
export const fetchInvoiceDataError = (error) => {
	console.log(error);
	return {
		type: FETCH_INVOICE_DATA_ERROR,
		error
	};
};

export const FETCH_INVOICE_DATA_SUCCESS = 'FETCH_INVOICE_DATA_SUCCESS';
export const fetchInvoiceDataSuccess = (data) => ({
	type: FETCH_INVOICE_DATA_SUCCESS,
	data
});

export const FETCH_SINGLE_INVOICE_DATA_SUCCESS = 'FETCH_SINGLE_INVOICE_DATA_SUCCESS';
export const fetchSingleInvoiceDataSuccess = (data) => ({
	type: FETCH_SINGLE_INVOICE_DATA_SUCCESS,
	data
});

export const POST_INVOICE_DATA_SUCCESS = 'POST_INVOICE_DATA_SUCCESS';
export const postInvoiceDataSuccess = () => ({
	type: POST_INVOICE_DATA_SUCCESS
});

export const POST_INVOICE_DATA_REQUEST = 'POST_INVOICE_DATA_REQUEST';
export const postInvoiceDataRequest = () => ({
	type: POST_INVOICE_DATA_REQUEST
});

export const POST_INVOICE_DATA_ERROR = 'POST_INVOICE_DATA_ERROR';
export const postInvoiceDataError = (error) => ({
	type: POST_INVOICE_DATA_ERROR,
	error
});

export const RESET_INVOICE_DATA = 'RESET_INVOICE_DATA';
export const resetInvoiceData = () => ({
	type: RESET_INVOICE_DATA
});

export const fetchInvoiceData = (authToken, customerID) => (dispatch) => {
	dispatch(fetchInvoiceDataRequest());
	return fetch(`${API_BASE_URL}/invoices/find/all/${customerID}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken}`,
			'Content-Type': 'application/json',
			Accept: 'application/json'
		}
	})
		.then(async (res) => {
			const json = await res.json();

			if (!res.ok) {
				return Promise.reject(json.message);
			}

			return json;
		})
		.then((invoiceData) => {
			const { invoices: rawInvoices } = invoiceData;
			const invoices = rawInvoices.map((invoice) => {
				const { dueDate, balance, origAmt, pending } = invoice;

				const status = (() => {
					if (pending) return 'pending';
					if (!balance) return 'paid';
					if (moment(dueDate).isBefore(moment(), 'd')) return 'overdue';
					if (origAmt > balance) return 'partial';

					return 'unpaid';
				})();

				return { ...invoice, status };
			});

			dispatch(fetchInvoiceDataSuccess(invoices));
		})
		.catch((err) => dispatch(fetchInvoiceDataError(err)));
};

export const fetchSingleInvoiceData = (customerID, invoiceID) => (dispatch) => {
	const { authToken } = store.getState().auth;

	dispatch(fetchInvoiceDataRequest());
	return fetch(`${API_BASE_URL}/invoices/find/single/${customerID}/${invoiceID}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${authToken}`,
			'Content-Type': 'application/json',
			Accept: 'application/json'
		}
	})
		.then(async (res) => {
			const json = await res.json();

			if (!res.ok) {
				return Promise.reject(json.message);
			}

			return json;
		})
		.then((invoiceData) => {
			const { invoice: rawInvoice } = invoiceData;

			const { dueDate, balance, origAmt, pending } = rawInvoice || {};

			const status = (() => {
				if (pending) return 'pending';
				if (!balance) return 'paid';
				if (moment(dueDate).isBefore(moment(), 'd')) return 'overdue';
				if (origAmt > balance) return 'partial';

				return 'unpaid';
			})();

			const invoice = { ...rawInvoice, status };

			dispatch(fetchSingleInvoiceDataSuccess(invoice));
		})
		.catch((err) => {
			console.log(err);
			dispatch(fetchInvoiceDataError(err));
		});
};

export const postInvoicePayment = (data) => (dispatch) => {
	const {
		currentUser,
		invoices,
		payments: rawPayments,
		customerEmail,
		customerPhone,
		billingAddress,
		total,
		lastFour,
		ccType,
		month,
		year,
		isPayPal,
		payPalId,
		payPalAuthorizationId,
		payPalCaptureId
	} = data;

	const customerID = currentUser.anonymous ? data.customerID : currentUser.customerID;

	const paymentMethod = lastFour ? { GUID: '', lastFour, ccType, expiration: { month, year } } : null;

	const payments = rawPayments.filter((payment) => !!payment.payAmt);

	const newPayment = {
		customerID,
		emailAddress: customerEmail,
		telephoneNo: customerPhone,
		billingAddress,
		payment: paymentMethod,
		tokenize: null,
		amount: total,
		pending: true,
		isPayPal,
		payPalId,
		payPalAuthorizationId,
		payPalCaptureId,
		paymentSubmitDate: moment().format('YYYY/MM/DD HH:mm'),
		paymentCollectDate: '',
		payments
	};

	const updatedInvoices = invoices
		.filter((invoice) => !!payments.find((payment) => payment.invoiceID === invoice.invoiceID))
		.map((invoice) => ({ ...invoice, pending: true }));

	dispatch(postInvoiceDataRequest());
	fetch(`${API_BASE_URL}/invoices/submit`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		body: JSON.stringify({ updatedInvoices, newPayment })
	})
		.then((res) => {
			if (!res.ok) {
				return Promise.reject(res.message);
			}

			dispatch(postInvoiceDataSuccess());
		})
		.catch((error) => {
			console.log(error);
			dispatch(postInvoiceDataError(error));
		});
};
