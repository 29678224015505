import React from 'react';

import { HIDE_CATEGORIES } from './config';
import { formatCategoryName } from './scripts/functions';

const createDropdownData = (navigation, functions, isLoggedIn) => {
    const topLevel = navigation.categories.reduce(
        (all, category) => {
            const { active, id, name } = category;

            if (HIDE_CATEGORIES.includes(id)) {
                return all;
            }

            if (!active || !(id.length === 3 || id.startsWith('000'))) {
                return all;
            }

            if (id === '999') {
                all[id] = {
                    title: 'Clearance',
                    style: {},
                    badge: '',
                    link: '/clearance',
                    subs: [[]],
                    order: id,
                };

                return all;
            }

            all[id] = {
                title: name,
                style: {},
                badge: id.startsWith('000') ? 'My Items' : '',
                link: `/category/${formatCategoryName(name)}`,
                subs: [[]],
                order: id,
            };

            return all;
        },
        isLoggedIn
            ? {
                  favorites: {
                      title: (
                          <span>
                              <i className="fas fa-heart" />
                              <span>{'\u2002'}My Favorites</span>
                          </span>
                      ),
                      style: {},
                      badge: '',
                      link: '/favorites',
                      subs: [[]],
                      order: '0',
                  },
              }
            : {}
    );

    const brands = navigation.brands.reduce((all, brand) => {
        const { id, name } = brand;

        all[id] = {
            title: name,
            style: {},
            link: `/brands/${id.toLowerCase()}`,
            subs: [[]],
            order: id,
        };

        return all;
    }, {});

    const dropdown = {
        labels: {
            top: {
                name: 'Shop by label type',
                contents: {
                    rolls: {
                        title: 'Rolls',
                        style: {},
                        badge: '',
                        subs: [
                            [
                                {
                                    img: 'custom-button.png',
                                    name: 'Custom Labels',
                                    link: '',
                                    categories: [
                                        {
                                            name: 'Blank Labels',
                                            link: '/build/rolls',
                                        },
                                        {
                                            name: 'Printed Labels',
                                            link: '/build/custom',
                                        },
                                        {
                                            name: 'Price Gun Labels',
                                            link: '/build/price-gun',
                                        },
                                    ],
                                },
                                {
                                    img: 'primera-category_300.png',
                                    name: 'Dye & Pigment',
                                    link: '',
                                    categories: [
                                        {
                                            name: 'Custom Laser & Inkjet Labels',
                                            link: '/build/rolls',
                                        },
                                        {
                                            name: 'Stock 2" Core Labels',
                                            link: '/products?show=185.010',
                                        },
                                        {
                                            name: 'Stock 3" Core Labels 1',
                                            link: '/products?show=185.020.010',
                                        },
                                        {
                                            name: 'Stock 3" Core Labels 2',
                                            link: '/products?show=185.020.020',
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    img: 'thermal-category_300.png',
                                    name: 'Thermal',
                                    link: '',
                                    categories: [
                                        {
                                            name: 'Custom Thermal Labels',
                                            link: '/build/rolls',
                                        },
                                        {
                                            name: 'Direct Thermal Labels',
                                            link: '/products?show=140.040.010',
                                        },
                                        {
                                            name: 'Thermal Transfer Labels',
                                            link: '/products?show=140.040.020',
                                        },
                                        {
                                            name: 'Direct Thermal Tags',
                                            link: '/products?show=140.040.030',
                                        },
                                        {
                                            name: 'Consignment Labels',
                                            link: '/products?show=137.020',
                                        },
                                        {
                                            name: 'Consignment Tags',
                                            link: '/products?show=137.010',
                                        },
                                        {
                                            name: 'Dymo-Compatible Labels',
                                            link: '/products?show=130',
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    img: 'StockLabels.png',
                                    name: 'Pre-Printed',
                                    link: '/products?show=190',
                                    categories: [
                                        {
                                            name: 'Custom Printed Labels',
                                            link: '/build/custom',
                                        },
                                        {
                                            name: 'Shipping Labels',
                                            link: '/products?show=190.020',
                                        },
                                        {
                                            name: 'Consecutive Number Labels',
                                            link: '/products?show=190.030',
                                        },
                                        {
                                            name: 'Pricing Labels',
                                            link: '/products?show=190.010.010',
                                        },
                                        {
                                            name: 'Promotional Labels',
                                            link: '/products?show=190.010.020',
                                        },
                                        {
                                            name: 'Status Labels',
                                            link: '/products?show=190.010.030',
                                        },
                                        {
                                            name: 'Month & Date Labels',
                                            link: '/products?show=190.010.040',
                                        },
                                        {
                                            name: 'Color Coding Labels',
                                            link: '/products?show=190.010.050',
                                        },
                                        {
                                            name: 'Markdown Labels',
                                            link: '/products?show=190.010.060',
                                        },
                                        {
                                            name: 'Wafer Seals',
                                            link: '/products?show=190.010.070',
                                        },
                                        {
                                            name: 'Cigar-Branding Products',
                                            link: '/products?show=190.010.080',
                                        },
                                        {
                                            name: 'Kenco® Sealer Products',
                                            link: '/products?show=190.040',
                                        },
                                    ],
                                },
                            ],
                        ],
                    },
                    sheets: {
                        title: 'Sheets',
                        style: {},
                        badge: 'New!',
                        subs: [
                            [
                                {
                                    img: 'custom-button.png',
                                    name: 'Custom Label Sheets',
                                    link: '/build/sheets',
                                    categories: [
                                        {
                                            name: 'Any Size, Any Material!',
                                            link: '/build/sheets',
                                        },
                                    ],
                                },
                                {
                                    img: 'address-labels-category.png',
                                    name: 'Address Labels',
                                    link: '',
                                    categories: [
                                        {
                                            name: 'Return Address Labels',
                                            link: '/products?show=145.010',
                                        },
                                        {
                                            name: 'Address Labels, Small',
                                            link: '/products?show=145.020',
                                        },
                                        {
                                            name: 'Address Labels, Large',
                                            link: '/products?show=145.030',
                                        },
                                    ],
                                },
                                {
                                    img: 'address-labels-category.png',
                                    name: 'Shipping Labels',
                                    link: '',
                                    categories: [
                                        {
                                            name: 'Shipping Labels, Small',
                                            link: '/products?show=145.033',
                                        },
                                        {
                                            name: 'Shipping Labels, Large',
                                            link: '/products?show=145.035',
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    img: 'KSDT50W_01.jpg',
                                    name: 'Stock Rounded',
                                    link: '',
                                    categories: [
                                        {
                                            name: '1½" × 1" Labels',
                                            link: '/products?show=180.010.370',
                                        },
                                        {
                                            name: '1¾" × ½" Labels',
                                            link: '/products?show=180.010.010',
                                        },
                                        {
                                            name: '1.813" × ½" Labels',
                                            link: '/products?show=180.010.390',
                                        },
                                        {
                                            name: '2" × 1.563" Labels',
                                            link: '/products?show=180.010.310',
                                        },
                                        {
                                            name: '2⅜" × ⅞" Labels',
                                            link: '/products?show=180.010.340',
                                        },
                                        {
                                            name: '2⅜" × 1" Labels',
                                            link: '/products?show=180.010.020',
                                        },
                                        {
                                            name: '2.688" × 2" Labels',
                                            link: '/products?show=180.010.300',
                                        },
                                        {
                                            name: '3‌7⁄16" × ⅔" Labels',
                                            link: '/products?show=180.010.080',
                                        },
                                        {
                                            name: '3½" × ¾" Labels',
                                            link: '/products?show=180.010.320',
                                        },
                                        {
                                            name: '3¾" × 2.438" Labels',
                                            link: '/products?show=180.010.220',
                                        },
                                        {
                                            name: '4" × 1" Labels',
                                            link: '/products?show=180.010.030',
                                        },
                                        {
                                            name: '4" × 1⅓" Labels',
                                            link: '/products?show=180.010.040',
                                        },
                                        {
                                            name: '4" × 1‌7⁄16" Labels',
                                            link: '/products?show=180.010.280',
                                        },
                                        {
                                            name: '4" × 1½" Labels',
                                            link: '/products?show=180.010.100',
                                        },
                                        {
                                            name: '4" × 1¾" Labels',
                                            link: '/products?show=180.010.270',
                                        },
                                        {
                                            name: '4"× 2" Labels',
                                            link: '/products?show=180.010.050',
                                        },
                                        {
                                            name: '4" × 2½" Labels',
                                            link: '/products?show=180.010.120',
                                        },
                                        {
                                            name: '4" × 3" Labels',
                                            link: '/products?show=180.010.190',
                                        },
                                        {
                                            name: '4" × 3¼" Labels',
                                            link: '/products?show=180.010.200',
                                        },
                                        {
                                            name: '4" × 3⅓" Labels',
                                            link: '/products?show=180.010.060',
                                        },
                                        {
                                            name: '4" × 5" Labels',
                                            link: '/products?show=180.010.180',
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    img: 'KSDT550W_01.jpg',
                                    name: 'Stock Squared',
                                    link: '',
                                    categories: [
                                        {
                                            name: '1.42" × 1" Labels',
                                            link: '/products?show=180.010.400',
                                        },
                                        {
                                            name: '1½" × ½" Labels',
                                            link: '/products?show=180.010.410',
                                        },
                                        {
                                            name: '1½" × 1" Labels',
                                            link: '/products?show=180.010.360',
                                        },
                                        {
                                            name: '2⅚" × 1" Labels',
                                            link: '/products?show=180.010.330',
                                        },
                                        {
                                            name: '2⅚" × 2⅕" Labels',
                                            link: '/products?show=180.010.290',
                                        },
                                        {
                                            name: '2⅚" × 2¾" Labels',
                                            link: '/products?show=180.010.260',
                                        },
                                        {
                                            name: '4¼" × 2" Labels',
                                            link: '/products?show=180.010.240',
                                        },
                                        {
                                            name: '4¼" × 2¾" Labels',
                                            link: '/products?show=180.010.210',
                                        },
                                        {
                                            name: '4¼" × 5½" Labels',
                                            link: '/products?show=180.010.170',
                                        },
                                        {
                                            name: '8½" × 3½" Labels',
                                            link: '/products?show=180.010.160',
                                        },
                                        {
                                            name: '8½" × 5½" Labels',
                                            link: '/products?show=180.010.150',
                                        },
                                        {
                                            name: '8½" × 11" Labels',
                                            link: '/products?show=180.010.070',
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    img: 'KSDT350W_01.jpg',
                                    name: 'Stock Circle',
                                    link: '',
                                    categories: [
                                        {
                                            name: '1" Diameter Labels',
                                            link: '/products?show=180.010.380',
                                        },
                                        {
                                            name: '1⅔" Diameter Labels',
                                            link: '/products?show=180.010.120',
                                        },
                                        {
                                            name: '2½" Diameter Labels',
                                            link: '/products?show=180.010.130',
                                        },
                                        {
                                            name: '3⅓" Diameter Labels',
                                            link: '/products?show=180.010.140',
                                        },
                                    ],
                                },
                                {
                                    img: 'FBA-KSDT1102W_01.png',
                                    name: 'Lip Balm Labels',
                                    link: '',
                                    categories: [
                                        {
                                            name: '10-Sheet Pack',
                                            link: '/products?show=180.010.005&sku=FBA-KSDT1102W',
                                        },
                                        {
                                            name: '50-Sheet Pack',
                                            link: '/products?show=180.010.005&sku=FBA-KSDT1102W-50',
                                        },
                                    ],
                                },
                            ],
                        ],
                    },
                    tags: {
                        title: 'Tags',
                        style: {},
                        badge: '',
                        subs: [
                            [
                                {
                                    img: 'Coupon-Tags.jpg',
                                    name: 'Coupon Tags',
                                    link: '/products?show=150.015',
                                    categories: [],
                                },
                                {
                                    img: 'Merchandise_Tags.png',
                                    name: 'Merchandise Tags',
                                    link: '/products?show=150.018',
                                    categories: [],
                                },
                                {
                                    img: 'Attacher-and-Staple-Tags.jpg',
                                    name: 'Attacher Tags',
                                    link: '/products?show=150.020',
                                    categories: [],
                                },
                            ],
                            [
                                {
                                    img: 'Large_Strung_Promotional_Sa.jpg',
                                    name: 'Retail & Sale Tags',
                                    link: '/products?show=150.040',
                                    categories: [],
                                },
                                {
                                    img: 'Jewelry_Tags.jpg',
                                    name: 'Jewelry Tags',
                                    link: '/products?show=150.050',
                                    categories: [],
                                },
                                {
                                    img: 'Layaway-Alteration-Inventory-and-Button-Tags.jpg',
                                    name: (
                                        <span>
                                            Layaway Alteration
                                            <br />
                                            Inventory &amp; Button Tags
                                        </span>
                                    ),
                                    link: '/products?show=150.060',
                                    categories: [],
                                },
                            ],
                            [
                                {
                                    img: 'Shipping-and-Metal-Rim-Tags.jpg',
                                    name: (
                                        <span>
                                            Shipping &amp; Metal
                                            <br />
                                            Rim Tags
                                        </span>
                                    ),
                                    link: '/products?show=150.030',
                                    categories: [],
                                },
                                {
                                    img: 'consignment-tags-category.jpg',
                                    name: 'Consignment Tags',
                                    link: '/products?show=137.010',
                                    categories: [],
                                },
                            ],
                        ],
                    },
                },
            },
            bottom: {
                name: 'Shop by printer compatibility',
                contents: null,
            },
        },
        all: {
            top: {
                name: '',
                contents: topLevel,
            },
            bottom: {
                contents: null,
            },
        },
        brands: {
            top: { name: '', contents: brands },
            bottem: { contents: null },
        },
        build: {
            top: {
                name: '',
                contents: {
                    blank: {
                        title: 'Blank Labels on Rolls',
                        style: {},
                        badge: '',
                        link: `/build/rolls`,
                        subs: [[]],
                    },
                    sheets: {
                        title: 'Sheet-Fed Labels',
                        style: {},
                        badge: '',
                        link: `/build/sheets`,
                        subs: [[]],
                    },
                    custom: {
                        title: 'Custom Printed Labels',
                        style: {},
                        badge: '',
                        link: `/build/custom`,
                        subs: [[]],
                    },
                    customForm: {
                        title: 'Custom Labels Quote Request Form',
                        style: {},
                        badge: '',
                        link: `/custom-printed-form`,
                        subs: [[]],
                    },
                    selflaminating: {
                        title: 'Custom Printed Self-Laminating Labels',
                        style: {},
                        badge: '',
                        link: `/self-laminating`,
                        subs: [[]],
                    },
                    pricegun: {
                        title: 'Price Gun Labels',
                        style: {},
                        badge: '',
                        link: `/build/price-gun`,
                        subs: [[]],
                    },
                    barcode: {
                        title: 'Barcode Generator',
                        style: {},
                        badge: '',
                        link: `/barcodes`,
                        subs: [[]],
                    },
                },
            },
            bottom: { contents: null },
        },
        about: {
            top: {
                name: '',
                contents: {
                    about: {
                        title: 'Our Story',
                        style: {},
                        badge: '',
                        link: '/about',
                        subs: [[]],
                    },
                    services: {
                        title: 'Services',
                        style: {},
                        badge: '',
                        link: '/services',
                        subs: [[]],
                    },
                    trykenco: {
                        title: 'Try Kenco®',
                        style: {},
                        badge: '',
                        link: '/try',
                        subs: [[]],
                    },
                    kencolp: {
                        title: 'Kenco LP®',
                        style: {},
                        badge: '',
                        link: '/lp',
                        subs: [[]],
                    },
                },
            },
            bottom: { contents: null },
        },
        contact: {
            top: {
                name: '',
                contents: {
                    about: {
                        title: 'Send us a Message',
                        style: {},
                        badge: '',
                        link: '/contact',
                        subs: [[]],
                    },
                    services: {
                        title: 'Request a Quote for Custom Labels',
                        style: {},
                        badge: '',
                        link: '/custom-printed-form',
                        subs: [[]],
                    },
                },
            },
            bottom: { contents: null },
        },
        account: {
            top: {
                name: '',
                contents: {
                    manage: {
                        title: 'Manage Account',
                        style: {},
                        badge: '',
                        link: '/account',
                        subs: [[]],
                    },
                    cart: {
                        title: 'View Cart & Check Out',
                        style: {},
                        badge: '',
                        link: '/cart',
                        subs: [[]],
                    },
                    logout: {
                        title: (
                            <span>
                                <i className="fas fa-sign-out-alt"></i> Log Out
                            </span>
                        ),
                        style: {},
                        badge: '',
                        function: functions.handleLogout,
                        subs: [[]],
                    },
                },
            },
            bottom: { contents: null },
        },
    };

    return dropdown;
};

export default createDropdownData;
