import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default class NotFoundPage extends React.Component {
	state = { status: null, redirect: '' };

	componentDidMount = () => { this.determineStatus(); }

	determineStatus = () => {
		const { history } = this.props;
		const { location } = history;
		const { pathname } = location;

		if (pathname.startsWith('/Primera')) {
			this.setState({ status: 301, redirect: '/products?show=101' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/info/about-us')) {
			this.setState({ status: 301, redirect: '/about' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/Pricing-Guns')) {
			this.setState({ status: 301, redirect: '/products?show=110.010' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/Tags')) {
			this.setState({ status: 301, redirect: '/products?show=150' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/Stock-Printed-Labels/Stock-Labels-by-KENCO')) {
			this.setState({ status: 301, redirect: '/products?show=190.010' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/Label-Applicators')) {
			this.setState({ status: 301, redirect: '/products?show=120' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/Thermal')) {
			this.setState({ status: 301, redirect: '/products?show=140' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/primera')) {
			// if (pathname.endsWith('labels')) {
			// 	this.setState({ redirect: '/products?show=101.010' }, () => history.replace(this.state.redirect));
			// } else if (pathname.endsWith('lx400-px450')) {
			// 	this.setState({ redirect: '/products?show=101.010.010' }, () => history.replace(this.state.redirect));
			// } else if (pathname.endsWith('lx500-lx500c')) {
			// 	this.setState({ redirect: '/products?show=101.010.020' }, () => history.replace(this.state.redirect));
			// } else if (pathname.endsWith('lx800-lx810')) {
			// 	this.setState({ redirect: '/products?show=101.010.030' }, () => history.replace(this.state.redirect));
			// } else if (pathname.endsWith('lx900-lx910')) {
			// 	this.setState({ redirect: '/products?show=101.010.040' }, () => history.replace(this.state.redirect));
			// } else if (pathname.endsWith('lx1000-lx2000')) {
			// 	this.setState({ redirect: '/products?show=101.010.050' }, () => history.replace(this.state.redirect));
			// }
		} else if (pathname.startsWith('/ijkit')) {
			this.setState({ redirect: '/products?show=125&sku=KENCO-LP-IJKIT' }, () => history.replace(this.state.redirect));
		} else if (pathname.startsWith('/dtkit')) {
			this.setState({ redirect: '/products?show=125&sku=KENCO-LP-DTKIT' }, () => history.replace(this.state.redirect));
		} else {
			this.setState({ status: 404 });
		}
	}

	render() {
		const { status, redirect } = this.state;

		return (
			<div className="page-container results-page-container notfound-page-container static-container">
				<Helmet>
					<meta name="prerender-status-code" content={status} />
					{status === 301 && redirect ? <meta name="prerender-header" content={`Location: https://${window.location.hostname}${redirect}`} /> : ''}
				</Helmet>
				<div className="notfound-page static-page">
					<h3 className="page-header notfound-header">{status === 301 ? 'Redirecting...' : 'Oops! Page not found (404).'}</h3>
					<p>
						<Link to="/">
							<span className="bold blue orange">Click here</span>
						</Link>
						<span> to return to the home page.</span>
					</p>
				</div>
			</div>
		);
	}
}
