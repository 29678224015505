export const SET_LOCAL_CART = 'SET_LOCAL_CART';
export const setLocalCart = localCart => ({
	type: SET_LOCAL_CART,
	localCart,
});

export const UPDATE_LOCAL_CART = 'UPDATE_LOCAL_CART';
export const updateLocalCart = (item, quantity) => ({
	type: UPDATE_LOCAL_CART,
	item,
	quantity,
});

export const REMOVE_FROM_LOCAL_CART = 'REMOVE_FROM_LOCAL_CART';
export const removeFromLocalCart = sku => ({
	type: REMOVE_FROM_LOCAL_CART,
	sku,
});

export const CLEAR_LOCAL_CART = 'CLEAR_LOCAL_CART';
export const clearLocalCart = () => ({
	type: CLEAR_LOCAL_CART,
});
