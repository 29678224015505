import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';

import { API_BASE_URL } from '../config';

const materials = [
    'Direct Thermal Paper',
    'Direct Thermal Synthetic',
    'Thermal Transfer Paper',
    'Thermal Transfer Synthetic',
    'Matte Paper Laser Label Sheets',
    'Gloss Paper Laser Label Sheets',
    'Clear Labels',
    'Silver or Gold Metalized Paper',
    'Silver Polyester',
    'White Paper Labels',
    'White Durable/Weatherproof Labels',
    'Opaque/Cover-Up Labels',
    'Fluorescent Paper Labels',
    'Tag / Non-Adhesive',
    'Other',
];
const adhesives = [
    'Permanent',
    'Removable',
    'Super Sticky',
    'High Performance',
    'Ultra Removable',
    'Tag (No Adhesive)',
    'Freezer',
    'Other',
];
const supplieds = [
    'On Rolls for Hand Application',
    'On Rolls for Machine Application',
    'Individual for Hand Application',
    'For Use in Printer',
    'Other',
];
const imprints = [
    'Blank',
    'Custom-Printed',
    'Consecutive Numbering or Variable Data',
    'Other',
];
const additionals = [
    'Perforation Between Labels',
    'Gloss Lamination',
    'Matte Lamination',
    'Multiple Copy Changes',
    'Doming',
    'Hot Stamping or Embossing',
    'Self Laminating Flap',
    'Varnish',
    'Other',
];
const shapes = [
    'Round Corner Rectangle',
    'Square Corner Rectangle',
    'Circle',
    'Oval',
    'Special Shape',
    'Other',
];

export default function CustomPrintedForm() {
    const [material, setMaterial] = useState([]);
    const [materialOther, setMaterialOther] = useState();
    const [adhesive, setAdhesive] = useState([]);
    const [adhesiveOther, setAdhesiveOther] = useState();
    const [supplied, setSupplied] = useState([]);
    const [suppliedOther, setSuppliedOther] = useState();
    const [imprint, setImprint] = useState([]);
    const [imprintOther, setImprintOther] = useState();
    const [additional, setAdditional] = useState([]);
    const [additionalOther, setAdditionalOther] = useState();
    const [shape, setShape] = useState([]);
    const [shapeOther, setShapeOther] = useState();
    const [width, setWidth] = useState(3);
    const [height, setHeight] = useState(5);
    const [q1, setQ1] = useState(1000);
    const [q2, setQ2] = useState(5000);
    const [q3, setQ3] = useState(10000);
    const [comment, setComment] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [successful, setSuccessful] = useState();
    const [error, setError] = useState();

    const history = useHistory();

    const emailValid = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(email);
    const nameValid = !!firstName;

    const valid = emailValid && nameValid;

    const toggleValue = (value, setFunction, option) => {
        if (value.includes(option)) {
            setFunction(value.filter((item) => item !== option));
        } else {
            setFunction([...value, option]);
        }
    };

    const renderOptions = (
        options,
        value,
        setFunction,
        valueOther,
        setFunctionOther,
        header,
        helperText
    ) => {
        return (
            <section>
                <FormControl component="fieldset">
                    <h2 className="blue">{header}</h2>
                    <p
                        style={{
                            marginTop: '-0.5rem',
                            marginBottom: '1rem',
                        }}
                    >
                        {helperText}
                    </p>
                    <FormGroup>
                        {options.map((option) => {
                            if (option === 'Other' && setFunctionOther) {
                                return (
                                    <TextField
                                        label="Other"
                                        value={valueOther}
                                        onChange={(e) =>
                                            setFunctionOther(e.target.value)
                                        }
                                        multiline
                                        helperText="Feel free to write in an additional
                                            option here if you don't see a match
                                            for your needs."
                                        minRows={2}
                                    />
                                );
                            }

                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value.includes(option)}
                                            size="small"
                                            onChange={() =>
                                                toggleValue(
                                                    value,
                                                    setFunction,
                                                    option
                                                )
                                            }
                                            name={option}
                                        />
                                    }
                                    label={option}
                                />
                            );
                        })}
                    </FormGroup>
                </FormControl>
            </section>
        );
    };

    const handleSubmitCustom = async () => {
        const body = JSON.stringify({
            customMaterial: [...material, materialOther].join(', '),
            labelQuantity: [q1, q2, q3].join(', '),
            supplied: [...supplied, suppliedOther].join(', '),
            adhesive: [...adhesive, adhesiveOther].join(', '),
            imprint: [...imprint, imprintOther].join(', '),
            additional: [...additional, additionalOther].join(', '),
            comment,
            width,
            height,
            shape: [...shape, shapeOther].join(', '),
            contact: {
                firstName,
                lastName,
                company,
                telephoneNo: phone,
                email,
            },
        });

        setSubmitted(true);

        const isSuccessful = await fetch(`${API_BASE_URL}/custom/quote`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body,
        })
            .then((res) => res.ok)
            .catch(() => false);

        if (isSuccessful) {
            setSuccessful(true);
        } else {
            setError(true);
        }
    };

    const handleClose = (redirect) => {
        if (redirect) {
            history.push('/');
        } else {
            setSubmitted(false);
            setSuccessful(false);
            setError(false);
        }
    };

    return (
        <div className="page-holder">
            <Helmet>
                <title>
                    Custom Printed Labels Quote Request Form | Kenco Label &amp;
                    Tag
                </title>
                <meta
                    name="description"
                    content="Fill out our quick quote request form and one of our label design experts will contact you shortly to discuss your needs."
                />
            </Helmet>
            <Dialog
                open={submitted && (successful || error)}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {successful
                        ? 'Your Quote Request has been submitted!'
                        : "Uh-oh, it looks like there's an issue."}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {successful
                            ? 'Our label design exports will be in touch with you shortly to discuss your needs and provide a quote.'
                            : 'Please call us at 800-537-3336 to discuss your label needs with a label design expert.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button
                        onClick={() => handleClose(true)}
                        color="primary"
                        autoFocus
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="page-container results-page-container products-page-container">
                <div className="brand-top-header">
                    <h1 className="products-header brand-products-header">
                        Custom Printed Labels Quote Form
                    </h1>
                </div>
                <p className="category-subheader brand-page-description">
                    Use this form to describe your label needs. Once you submit
                    the form, one of our label design experts will shortly reach
                    out to discuss your needs and provide you with quotes.
                </p>
                <form>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: '2rem',
                        }}
                    >
                        {renderOptions(
                            materials,
                            material,
                            setMaterial,
                            materialOther,
                            setMaterialOther,
                            '1.  Materials',
                            'Select a label material.'
                        )}
                        {renderOptions(
                            adhesives,
                            adhesive,
                            setAdhesive,
                            adhesiveOther,
                            setAdhesiveOther,
                            '2.  Adhesives',
                            'Select an adhesive for your labels.'
                        )}
                        {renderOptions(
                            supplieds,
                            supplied,
                            setSupplied,
                            suppliedOther,
                            setSuppliedOther,
                            '3.  Supplied',
                            'Indicate how you would like your labels supplied.'
                        )}
                        {renderOptions(
                            imprints,
                            imprint,
                            setImprint,
                            imprintOther,
                            setImprintOther,
                            '4.  Imprint',
                            'Select the type of print you need. If you have custom artwork, you can submit to us via email once one of our label experts contacts you. Our design artists are also available to help you create a design at no additional cost!'
                        )}
                        {renderOptions(
                            additionals,
                            additional,
                            setAdditional,
                            additionalOther,
                            setAdditionalOther,
                            '5.  Additional Features',
                            'Select any additional features you would like your label to have.'
                        )}
                        {renderOptions(
                            shapes,
                            shape,
                            setShape,
                            shapeOther,
                            setShapeOther,
                            '6.  Shape',
                            'Indicate the shape of your labels.'
                        )}
                        <section>
                            <FormControl component="fieldset">
                                <h2 className="blue">7. Dimensions</h2>
                                <p
                                    style={{
                                        marginTop: '-0.5rem',
                                        marginBottom: '2rem',
                                    }}
                                >
                                    Input the width and height of your label in
                                    inches.
                                </p>
                                <FormGroup>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '1rem',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <TextField
                                            type="number"
                                            label="Width"
                                            value={width}
                                            onChange={(e) =>
                                                setWidth(
                                                    `${parseFloat(
                                                        e.target.value,
                                                        10
                                                    )}`
                                                )
                                            }
                                            style={{ width: 100 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        in
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <div style={{ fontSize: '1.5rem' }}>
                                            ×
                                        </div>
                                        <TextField
                                            type="number"
                                            label="Height"
                                            value={height}
                                            onChange={(e) =>
                                                setHeight(
                                                    `${parseFloat(
                                                        e.target.value,
                                                        10
                                                    )}`
                                                )
                                            }
                                            style={{ width: 100 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        in
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                            </FormControl>
                        </section>
                        <section>
                            <FormControl component="fieldset">
                                <h2 className="blue">8. Quantities</h2>
                                <p
                                    style={{
                                        marginTop: '-0.5rem',
                                        marginBottom: '2rem',
                                    }}
                                >
                                    Specify the quantities of labels for which
                                    you would like quotes. Ordering a larger
                                    quantity of labels yields a lower cost per
                                    label.
                                </p>
                                <FormGroup>
                                    <TextField
                                        type="number"
                                        label="Quantity 1"
                                        value={q1}
                                        onChange={(e) =>
                                            setQ1(
                                                `${parseFloat(
                                                    e.target.value,
                                                    10
                                                )}`
                                            )
                                        }
                                        style={{
                                            width: 150,
                                            marginBottom: '2rem',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    labels
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        type="number"
                                        label="Quantity 2"
                                        value={q2}
                                        onChange={(e) =>
                                            setQ2(
                                                `${parseFloat(
                                                    e.target.value,
                                                    10
                                                )}`
                                            )
                                        }
                                        style={{
                                            width: 150,
                                            marginBottom: '2em',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    labels
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        type="number"
                                        label="Quantity 3"
                                        value={q3}
                                        onChange={(e) =>
                                            setQ3(
                                                `${parseFloat(
                                                    e.target.value,
                                                    10
                                                )}`
                                            )
                                        }
                                        style={{ width: 150 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    labels
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                        </section>
                        <section>
                            <FormControl
                                component="fieldset"
                                style={{ width: 400 }}
                            >
                                <h2 className="blue">9. Additional Comments</h2>
                                <TextField
                                    label="Write your comments here"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    multiline
                                    style={{ width: 'auto', maxWidth: 400 }}
                                    minRows={3}
                                />
                            </FormControl>
                        </section>
                    </div>
                    <section>
                        <FormControl
                            component="fieldset"
                            style={{ width: 300 }}
                        >
                            <h2 className="blue">10. Contact Information</h2>
                            <p
                                style={{
                                    marginTop: '-0.5rem',
                                    marginBottom: '2rem',
                                }}
                            >
                                Enter your contact information so that we can
                                reach you to deliver your quote.
                            </p>
                            <TextField
                                label="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                style={{
                                    width: 'auto',
                                    maxWidth: 200,
                                    marginBottom: '2rem',
                                }}
                                required
                            />
                            <TextField
                                label="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                style={{
                                    width: 'auto',
                                    maxWidth: 200,
                                    marginBottom: '2rem',
                                }}
                            />
                            <TextField
                                label="Company"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                style={{
                                    width: 'auto',
                                    maxWidth: 200,
                                    marginBottom: '2rem',
                                }}
                            />
                            <TextField
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{
                                    width: 'auto',
                                    maxWidth: 200,
                                    marginBottom: '2rem',
                                }}
                                required
                            />
                            <TextField
                                type="tel"
                                label="Telephone"
                                value={phone}
                                helperText="Digits only, no dashes or parentheses."
                                onChange={(e) => {
                                    const { value } = e.target;

                                    if (/^\d{0,}$/.test(value)) {
                                        setPhone(value);
                                    }
                                }}
                                style={{
                                    width: 'auto',
                                    maxWidth: 200,
                                    marginBottom: '2rem',
                                }}
                            />
                        </FormControl>
                    </section>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={!valid}
                        onClick={handleSubmitCustom}
                        id="custom-quote-form-button"
                    >
                        <span id="custom-quote-form-span">
                            Submit Quote Request
                        </span>
                    </Button>
                </form>
            </div>
        </div>
    );
}
