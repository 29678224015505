import {
	FETCH_CONFIGURATOR_DATA_REQUEST,
	FETCH_CONFIGURATOR_DATA_ERROR,
	FETCH_CONFIGURATOR_DATA_SUCCESS,
} from '../actions/configurator';

const initialState = {
	loading: false,
	error: false,
	data: null,
};

export default (state = initialState, action) => {
	if (action.type === FETCH_CONFIGURATOR_DATA_REQUEST) {
		return Object.assign({}, state, {
			loading: true,
		});
	} if (action.type === FETCH_CONFIGURATOR_DATA_ERROR) {
		return Object.assign({}, state, {
			loading: false,
			error: action.error,
		});
	} if (action.type === FETCH_CONFIGURATOR_DATA_SUCCESS) {
		return Object.assign({}, state, {
			loading: false,
			error: null,
			data: action.data,
		});
	}

	return state;
};
